import { getI18n } from 'react-i18next';

import {
  ConfigApi,
  EmployeeApi,
  EmployerApi,
  FetchService,
  ImportApi,
  TemplateApi,
  UserApi,
} from '@pib/api';
import DashboardApi from '@pib/api/dist/dashboard/DashboardApi';

export const fetchService = new FetchService({
  getLanguage: () => getI18n().language,
});
export const userApi = new UserApi(fetchService);
export const configApi = new ConfigApi(fetchService);
export const employerApi = new EmployerApi(fetchService);
export const dashboardApi = new DashboardApi(fetchService);
export const employeeApi = new EmployeeApi(fetchService);
export const templateApi = new TemplateApi(fetchService);
export const importApi = new ImportApi(fetchService);
