/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

export function debounce(func: (...args: any) => void, wait: number) {
  let timeout: any;
  return function d(...args: any) {
    // eslint-disable-next-line
    // @ts-ignore
    const context = this; // eslint-disable-line
    const later = function () {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function debounceEventHandler<E extends ChangeEvent<HTMLInputElement>>(
  func: (e: E) => void,
  wait: number,
) {
  const debounced = debounce(func, wait);
  return function d(e: E) {
    e.persist();
    return debounced(e);
  };
}

export enum SortDirection {
  ASCENDING,
  DESCENDING,
}

export const sortBy = <Value, Prop extends keyof Value>(
  values: Value[],
  prop: Prop,
  direction: SortDirection,
) => {
  return [
    ...values.sort((a, b) => {
      const aValue = a[prop];
      const bValue = b[prop];

      if (
        aValue &&
        bValue &&
        typeof aValue === 'string' &&
        typeof bValue === 'string'
      ) {
        const aStringValue = aValue.trim().toLowerCase();
        const bStringValue = bValue.trim().toLowerCase();

        return (
          (aStringValue < bStringValue ? -1 : 1) *
          (direction === SortDirection.ASCENDING ? 1 : -1)
        );
      }

      return (
        (aValue < bValue ? -1 : 1) *
        (direction === SortDirection.ASCENDING ? 1 : -1)
      );
    }),
  ];
};

export function merge<T>(a: Record<string, T>, b: Record<string, T>) {
  return Object.keys(a).reduce<Record<string, T>>((acc, key) => {
    acc[key] = {
      ...a[key],
      ...b[key],
    };
    return acc;
  }, {});
}

export const throttle = (func: (...args: any) => void, limit: number) => {
  let inThrottle: boolean;
  return function t(...args: any) {
    // eslint-disable-next-line
    // @ts-ignore
    const context = this; // eslint-disable-line
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

export const usePersistedState = <T>(key: string, defaultValue: T) => {
  const [state, setState] = useState(
    () => JSON.parse(localStorage.getItem(key)!) || defaultValue,
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};
