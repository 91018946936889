import type { FC } from 'react';

interface Props {
  code?: string;
  label: string;
}

const HttpCodePage: FC<Props> = ({ code, label, children }) => {
  return (
    <div className="flex flex-col items-center justify-center bg-primary-500 w-screen h-screen">
      {code && <h1 className="text-white m-0 leading-none text-6xl">{code}</h1>}
      <h2 className="text-white">{label}</h2>
      <a href="/" className="btn bg-white">
        Home
      </a>
      {children}
    </div>
  );
};

export default HttpCodePage;
