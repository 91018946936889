import type { CSSProperties, FC } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { useSmall } from 'utils';

import { Avatar, Icon } from '..';

interface Props {
  style: CSSProperties;
  to: string;
  title: string;
  description?: string;
  isSelected: boolean;
}

const SearchListItem: FC<Props> = ({
  style,
  title,
  description,
  to,
  isSelected,
}) => {
  const isSmall = useSmall();

  return (
    <Link
      style={style}
      className={clsx(
        'flex items-center px-3 py-3 hover:bg-primary-600 transition-colors duration-200 cursor-pointer',
        isSelected && 'bg-primary-600',
      )}
      to={to}
    >
      {!isSmall && <Avatar name={title} />}
      <div className="flex flex-col px-2 w-full overflow-hidden">
        <div className="truncate font-bold">{title}</div>
        <div className="truncate">{description}</div>
      </div>
      <Icon type="ellipsis-v" />
    </Link>
  );
};

export default SearchListItem;
