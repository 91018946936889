import type {
  FC,
  InputHTMLAttributes,
  ReactNode,
  TextareaHTMLAttributes,
} from 'react';
import styled, { css } from 'styled-components';

import { hex2rgb } from './helpers';
import { withBoxShadow } from './props';

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  color: ${p => p.theme.text};
  cursor: pointer;
  margin-right: 20px;
  transition: all 0.3s linear;
  &:before {
    display: inline-block;
    content: '';
    height: 18px;
    width: 18px;
    min-width: 18px;
    margin-right: 10px;
    border: 5px solid ${p => p.theme.text};
    border-radius: 50%;
    transition: all 0.25s linear;
  }
`;

interface RadioInputComposition {
  Label: typeof RadioLabel;
}

// eslint-disable-next-line
// @ts-ignore
const RadioInput: FC<InputHTMLAttributes<HTMLInputElement>> &
  RadioInputComposition = styled.input.attrs({
  type: 'radio',
})`
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  width: 0;
  position: absolute;
  &:checked + ${RadioLabel} {
    &:before {
      border: 5px solid ${p => p.theme.secondary};
      background: ${p => p.theme.white};
    }
  }
`;

RadioInput.Label = RadioLabel;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  color: ${p => p.theme.text};
  cursor: pointer;
  margin-right: 20px;
  transition: all 0.3s linear;
  &:before {
    display: inline-block;
    content: '';
    height: 30px;
    width: 30px;
    min-width: 30px;
    margin-right: 15px;
    border: 3px solid ${p => p.theme.text};
    padding-bottom: 28px;
    transition: all 0.25s linear;
  }
`;

interface CheckboxInputComposition {
  Label: typeof CheckboxLabel;
}

// eslint-disable-next-line
// @ts-ignore
const CheckboxInput: FC<InputHTMLAttributes<HTMLInputElement>> &
  CheckboxInputComposition = styled.input.attrs({
  type: 'checkbox',
})`
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  width: 0;
  position: absolute;
  &:checked + ${CheckboxLabel} {
    &:before {
      content: '✓';
      color: ${p => p.theme.secondary};
      font-size: 1.3rem;
      border: 3px solid ${p => p.theme.secondary};
      background: ${p => p.theme.white};
      text-align: center;
    }
  }
`;

CheckboxInput.Label = CheckboxLabel;

const StyledInput = styled.input<{ error?: boolean }>`
  width: 100%;
  padding: 5px 10px;
  margin: 0;
  border: 1px solid ${p => (p.error ? p.theme.error : p.theme.border)};
  background-color: ${p => p.theme.white};
  color: ${p => p.theme.text};
  transition: all 0.3s;
  border-radius: 5px;
  line-height: 1.2;
  &:disabled {
    background-color: ${p => p.theme.border};
    cursor: not-allowed;
  }
  &:hover {
    border-color: ${p => p.theme.primary};
  }
  &:focus {
    border-color: ${p => p.theme.primary};
    outline: 0;
    ${p => withBoxShadow({ spread: 2, color: p.theme.primary, opacity: 0.1 })};
  }
  ::-webkit-input-placeholder {
    color: ${p => hex2rgb(p.theme.text, 0.4)};
  }
  :-moz-placeholder {
    color: ${p => hex2rgb(p.theme.text, 0.4)};
  }
  ::-moz-placeholder {
    color: ${p => hex2rgb(p.theme.text, 0.4)};
  }
  :-ms-input-placeholder {
    color: ${p => hex2rgb(p.theme.text, 0.4)};
  }
  ::-ms-input-placeholder {
    color: ${p => hex2rgb(p.theme.text, 0.4)};
  }
  :placeholder-shown {
    color: ${p => hex2rgb(p.theme.text, 0.4)};
  }
  ${p =>
    p.error &&
    css`
      &:hover {
        border-color: ${p.theme.error};
      }
      &:focus {
        border-color: ${p.theme.error};
        outline: 0;
        ${withBoxShadow({ spread: 2, color: p.theme.error, opacity: 0.1 })};
      }
    `};
`;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  error?: boolean;
}

const Input: FC<InputProps> = ({ className, children, ...rest }) => (
  <StyledInput type="text" className={className} {...rest}>
    {children}
  </StyledInput>
);

const StyledTextarea = styled.textarea<{ error?: boolean }>`
  width: 100%;
  padding: 5px 10px;
  margin: 0;
  border: 1px solid ${p => p.theme.border};
  background-color: ${p => p.theme.white};
  color: ${p => p.theme.text};
  transition: all 0.3s;
  border-radius: 5px;
  &:disabled {
    background-color: ${p => p.theme.border};
    cursor: not-allowed;
  }
  &:hover {
    border-color: ${p => p.theme.primary};
  }
  &:focus {
    border-color: ${p => p.theme.primary};
    outline: 0;
    ${p => withBoxShadow({ spread: 2, color: p.theme.primary, opacity: 0.1 })};
  }
  ::-webkit-input-placeholder {
    color: ${p => hex2rgb(p.theme.text, 0.4)};
  }
  :-moz-placeholder {
    color: ${p => hex2rgb(p.theme.text, 0.4)};
  }
  ::-moz-placeholder {
    color: ${p => hex2rgb(p.theme.text, 0.4)};
  }
  :-ms-input-placeholder {
    color: ${p => hex2rgb(p.theme.text, 0.4)};
  }
  ::-ms-input-placeholder {
    color: ${p => hex2rgb(p.theme.text, 0.4)};
  }
  :placeholder-shown {
    color: ${p => hex2rgb(p.theme.text, 0.4)};
  }
  ${p =>
    p.error &&
    css`
      &:hover {
        border-color: ${p.theme.error};
      }
      &:focus {
        border-color: ${p.theme.error};
        outline: 0;
        ${withBoxShadow({ spread: 2, color: p.theme.error, opacity: 0.1 })};
      }
    `};
`;

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  error?: boolean;
  children?: ReactNode;
}

const TextArea = ({ className, children, ...rest }: TextAreaProps) => (
  <StyledTextarea className={className} {...rest}>
    {children}
  </StyledTextarea>
);

export { Input, TextArea, RadioInput, CheckboxInput };
