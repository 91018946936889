import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { MyBenWhite } from 'assets/svg';
import clsx from 'clsx';

export default () => {
  const { i18n } = useTranslation();
  const [language] = i18n.language.split('-');
  const queryClient = useQueryClient();

  const onChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    queryClient.invalidateQueries({ refetchActive: true });
    window.location.reload();
  };

  return (
    <header className="px-2 h-32 py-4 flex justify-between bg-tertiary-500 text-white">
      <Link to="/employees" className="flex items-center">
        <MyBenWhite className="mx-4 h-32" />
      </Link>
      <div className="flex flex-row items-center">
        <button
          type="button"
          className={clsx({ 'font-bold': language === 'en' })}
          onClick={() => onChangeLanguage('en')}
        >
          EN
        </button>
        <div className="px-2">-</div>
        <button
          type="button"
          className={clsx({ 'font-bold': language === 'nl' })}
          onClick={() => onChangeLanguage('nl')}
        >
          NL
        </button>
        <div className="px-2">-</div>
        <button
          type="button"
          className={clsx({ 'font-bold': language === 'fr' })}
          onClick={() => onChangeLanguage('fr')}
        >
          FR
        </button>
      </div>
    </header>
  );
};
