import { useTranslation } from 'react-i18next';
import { PinB } from 'assets/svg';

import { useConfig } from 'core/modules/config';

import Icon from '../Icon';

export default () => {
  const { config } = useConfig();
  const { t } = useTranslation();
  const keepOnlyNumbersAndPlus = (str: string) => {
    return str.replace(/[^\d+]/g, '');
  };
  const pinBContactEmail =
    config?.company.pinBContactEmail || t('common.email')!;
  const pinBContactNumber =
    config?.company.pinBContactNumber || t('common.phone')!;
  const cleanedNumber = keepOnlyNumbersAndPlus(pinBContactNumber);

  return (
    <footer className="text-neutral-100 primary-gradient">
      <div className="container flex justify-between items-center min-h-10">
        <PinB className="h-48 my-4" />
        <div className="flex flex-col">
          <div className="flex items-center">
            <Icon type="envelope" className="mr-2" />
            <a
              href={`mailto:${pinBContactEmail}`}
              className="text-neutral-100 hover:underline"
            >
              {pinBContactEmail}
            </a>
          </div>
          <div className="flex items-center">
            <Icon type="phone" className="mr-2" />
            <a
              href={`tel:${cleanedNumber}`}
              className="text-neutral-100 hover:underline"
            >
              {pinBContactNumber}
            </a>
          </div>
          <div className="flex items-center mb-2">
            <Icon type="map-marker-alt" className="mr-2" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/maps/place/Gravendreef+11%2Fbus+6,+9120+Beveren/@51.2108018,4.2574483,17z/data=!3m1!4b1!4m5!3m4!1s0x47c38b34687ae2ef:0x7387807c612af4!8m2!3d51.2108018!4d4.259637"
              className="hover:underline text-neutral-100"
            >
              Gravendreef 11 bus 6, 9120 Beveren
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
