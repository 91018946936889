export default ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 642.57 280.56"
    className={className}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="505.34"
        y1="196.42"
        x2="517.44"
        y2="185.28"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01" stopColor="#945026" />
        <stop offset="0.31" stopColor="#70452b" stopOpacity="0.63" />
        <stop offset="0.63" stopColor="#4f3b2f" stopOpacity="0.29" />
        <stop offset="0.87" stopColor="#3b3432" stopOpacity="0.08" />
        <stop offset="1" stopColor="#333233" stopOpacity="0" />
      </linearGradient>
    </defs>
    <title>mybenplus</title>
    <path
      fill="#b86534"
      d="M517.73,199.18h23.21V185.34H521.21a3.48,3.48,0,0,1-3.48-3.48V162.13H503.89v30.18a6.88,6.88,0,0,0,6.88,6.87Z"
    />
    <path
      fill="url('#linear-gradient')"
      d="M517.73,199.22H534a6.92,6.92,0,0,0,6.92-6.92h0a6.92,6.92,0,0,0-6.92-6.92H521.21a3.48,3.48,0,0,1-3.48-3.47V169.1a6.91,6.91,0,0,0-6.92-6.92h0a6.92,6.92,0,0,0-6.92,6.92v23.25a6.88,6.88,0,0,0,6.88,6.87Z"
    />
    <path
      fill="#ec8859"
      d="M503.88,185.35h-23.2v13.84h19.73a3.47,3.47,0,0,1,3.47,3.48V222.4h13.85V199.19a13.85,13.85,0,0,0-13.85-13.84Z"
    />
    <path
      fill="#4f7a9e"
      d="M101.63,116.51q2.7,4,6.67,9.73T117,138.65q4.68,6.67,9.65,13.63t9.58,13.41l8.45,11.86,6.24,8.8,4.61-6.68q3.34-4.81,7.81-11.21T173,154.62L183.31,140q5-7.16,9.51-13.34t7.31-10.15V222.4H189.91V150.15a25.51,25.51,0,0,1,.15-2.69c.09-1,.14-1.76.14-2.42q-2.41,3.41-5.75,8.16t-7,10.15l-7.52,11q-3.83,5.61-7.38,10.86t-6.6,9.79q-3.06,4.55-5,7.53-2-2.57-5.39-7.31T138,184.57q-4-5.88-8.45-12.21t-8.16-11.85q-3.77-5.53-6.67-9.72T110.85,145a17.26,17.26,0,0,0,.22,2.42,18.32,18.32,0,0,1,.21,2.69V222.4h-9.65Z"
    />
    <path
      fill="#4f7a9e"
      d="M223.43,249.65H215.2c1.22-2.56,2.34-5,3.33-7.17s2-4.47,2.91-6.74,2-4.66,3.05-7.17,2.34-5.32,3.76-8.44q-6.52-13.06-12.77-27.18t-12.77-27.33h8.37q4.26,8.52,7.38,15.33t5.61,12.42q2.48,5.61,4.47,10.43t4,9.37q2-4.54,3.9-9.37t4.41-10.43q2.48-5.61,5.6-12.42t7.38-15.33h8.38Z"
    />
    <path
      fill="#4f7a9e"
      d="M275.66,222.4V118.93h28.25a59.7,59.7,0,0,1,13.91,1.49,29.5,29.5,0,0,1,10.64,4.68,20.67,20.67,0,0,1,6.74,8.37,30.05,30.05,0,0,1,2.34,12.42,38.06,38.06,0,0,1-.78,7.74,24.48,24.48,0,0,1-2.55,7,20.17,20.17,0,0,1-4.61,5.61,19.43,19.43,0,0,1-7.1,3.69,32,32,0,0,1,7.17,2.41,21.71,21.71,0,0,1,11.07,11.86,29.87,29.87,0,0,1-1.14,23,25.75,25.75,0,0,1-7.66,8.73,32,32,0,0,1-11,5A53.15,53.15,0,0,1,308,222.4ZM293,134.68v28.1h13q7.25,0,10.72-4.25a15.65,15.65,0,0,0,3.48-10.22q0-7-4.26-10.29t-11.5-3.34Zm0,42.87v29.09H306.6a27.36,27.36,0,0,0,7.24-.92,18.81,18.81,0,0,0,6-2.7,12.18,12.18,0,0,0,4-4.54A14,14,0,0,0,325.2,192a12.86,12.86,0,0,0-1.71-6.88,13.13,13.13,0,0,0-4.54-4.47,19.63,19.63,0,0,0-6.46-2.41,38,38,0,0,0-7.45-.71Z"
    />
    <path
      fill="#4f7a9e"
      d="M401.31,215.44a32,32,0,0,1-10.65,6.25A37.18,37.18,0,0,1,377.89,224a32,32,0,0,1-12.57-2.49,31.51,31.51,0,0,1-10.21-6.81,33.06,33.06,0,0,1-6.89-10.08,30.82,30.82,0,0,1,0-24.55A33.31,33.31,0,0,1,355.11,170a32.22,32.22,0,0,1,10.07-6.81,30.24,30.24,0,0,1,12.14-2.48,27.92,27.92,0,0,1,17.95,6.17,33.36,33.36,0,0,1,11,16.25q-4.26,1.56-9.86,3.76l-11.71,4.62q-6.1,2.41-12.21,4.89t-11.21,4.47a19.18,19.18,0,0,0,6.67,7.1,17.65,17.65,0,0,0,9.65,2.7,21.91,21.91,0,0,0,7.45-1.35,17.57,17.57,0,0,0,6.18-3.62ZM389.38,180a10.33,10.33,0,0,0-5.11-4.61,16.44,16.44,0,0,0-6.67-1.49,17.77,17.77,0,0,0-7.24,1.49,18.2,18.2,0,0,0-5.82,4,19.47,19.47,0,0,0-3.83,5.68,16,16,0,0,0-1.42,6.6v.57l7.6-3.06c2.69-1.08,5.39-2.2,8.09-3.33s5.27-2.2,7.73-3.2S387.4,180.72,389.38,180Z"
    />
    <path
      fill="#4f7a9e"
      d="M409.43,162.07h11.92q.28,5.12.64,8.31c.23,2.13.45,4.52.64,7.17a30.55,30.55,0,0,1,9.22-12.35q6-4.69,14.91-4.69,9.23,0,14.69,5a18,18,0,0,1,5.75,13.13V222.4H453.71V185.07a12.34,12.34,0,0,0-3.12-7.6q-2.83-3.18-8.8-3.47a18.9,18.9,0,0,0-7.59,1.49,18.26,18.26,0,0,0-6,4,17.76,17.76,0,0,0-3.83,6.11,21.35,21.35,0,0,0-1.35,7.66v29.1H409.43Z"
    />
  </svg>
);
