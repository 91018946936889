import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import type { ApiConfig } from '@pib/api';

import ConfigQueryKeys from './ConfigQueryKeys';

export const configQueryKeys = new ConfigQueryKeys('config');

export const useSetConfigCache = () => {
  const queryClient = useQueryClient();

  const setConfigCache = useCallback(
    (config: ApiConfig) => {
      const queryKey = configQueryKeys.getConfig();
      return queryClient.setQueryData<ApiConfig>(queryKey, config);
    },
    [queryClient],
  );

  return setConfigCache;
};
