import type { ComponentType } from 'react';
import { Redirect } from 'react-router-dom';
import ErrorHandler from 'components/ErrorHandler';
import { LoadingLayout, LoadingLogin } from 'components/Loading';

import { useAuth } from '@pib/auth';
import { useConfig } from 'core/modules/config';

function withAuthRedirect<P = Record<string, unknown>>({
  WrappedComponent,
  LoadingComponent,
  expectedAuth,
  location,
}: {
  WrappedComponent: ComponentType<P>;
  LoadingComponent: ComponentType;
  expectedAuth: boolean;
  location: string;
}) {
  const WithAuthRedirectWrapper = (props: P) => {
    const { isLoggedIn, isLoading: isLoadingAuth } = useAuth();
    const { isLoading: isLoadingConfig, error: configError } = useConfig();

    if (configError) {
      return <ErrorHandler error={configError} />;
    }

    if (isLoadingAuth || isLoadingConfig) {
      return <LoadingComponent />;
    }
    if (expectedAuth !== isLoggedIn) {
      return <Redirect to={location} />;
    }
    return <WrappedComponent {...props} />;
  };
  return WithAuthRedirectWrapper;
}

export function withAuth<P>(
  WrappedComponent: ComponentType<P>,
  location = '/login',
) {
  return withAuthRedirect({
    LoadingComponent: LoadingLayout,
    WrappedComponent,
    location,
    expectedAuth: true,
  });
}

export function withoutAuth<P>(
  WrappedComponent: ComponentType<P>,
  location = '/employees',
) {
  return withAuthRedirect({
    LoadingComponent: LoadingLogin,
    WrappedComponent,
    location,
    expectedAuth: false,
  });
}
