import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { LoadingLayout, LoadingLogin } from 'components/Loading';

import { Footer, Header, withAuth, withoutAuth } from '../components';
import Dashboard from '../pages/dashboard/DashboardPage';
import UnauthorizedPage from '../pages/http/401/UnauthorizedPage';
import ForbiddenPage from '../pages/http/403/ForbiddenPage';
import NotFoundPage from '../pages/http/404/NotFoundPage';

const Login = lazy(
  () => import(/* webpackChunkName: "login" */ '../pages/login/LoginPage'),
);

const Employees = lazy(
  () =>
    import(
      /* webpackChunkName: "employees" */ '../pages/employees/EmployeesPage'
    ),
);
const Templates = lazy(
  () =>
    import(
      /* webpackChunkName: "templates" */ '../pages/templates/TemplatesPage'
    ),
);

export default () => {
  const { pathname } = useLocation();
  const isLogin = pathname.includes('login');

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <Suspense fallback={isLogin ? <LoadingLogin /> : <LoadingLayout />}>
        <Switch>
          <Route path="/401" exact component={UnauthorizedPage} />
          <Route path="/403" exact component={ForbiddenPage} />
          <Route path="/404" exact component={NotFoundPage} />
          <Route exact path="/login" component={withoutAuth(Login)} />
          <Route exact path="/login/itsme" component={withoutAuth(Login)} />
          <Route exact path="/dashboard" component={withAuth(Dashboard)} />
          <Route exact path="/employees" component={withAuth(Employees)} />
          <Route exact path="/templates" component={withAuth(Templates)} />
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </Suspense>
      <Footer />
    </div>
  );
};
