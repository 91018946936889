import { useQuery } from 'react-query';

import type { ApiConfig, ApiError } from '@pib/api';
import { configApi } from 'core/di';

import { configQueryKeys } from './cache';

export const useConfig = () => {
  const { data, isLoading, error } = useQuery<ApiConfig, ApiError>(
    configQueryKeys.getConfig(),
    () => configApi.getEmployerConfig(),
    {
      staleTime: Infinity,
    },
  );

  return {
    config: data,
    isLoading,
    error,
  };
};
