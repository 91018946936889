import type { ReactNode } from 'react';
import ctaImage from 'assets/images/cta-background-small.jpg';
import { MyBen } from 'assets/svg';
import { Spinner } from 'components';

import { version } from '../../../../package.json';

interface Props {
  logoUrl?: string;
  isLoading?: boolean;
  children?: ReactNode;
}

const Wrapper = ({ children, logoUrl, isLoading }: Props) => {
  return (
    <>
      <img
        src={ctaImage}
        className="max-h-banner w-full object-cover"
        alt="cta-banner"
      />
      <div className="flex-1 bg-neutral-100 flex justify-center items-center">
        <div className="bg-white px-6 pt-6 pb-2 shadow-md rounded w-full max-w-login m-4">
          {logoUrl && <img src={logoUrl} alt="logo" className="mx-auto mb-4" />}
          {!logoUrl && <MyBen className="mx-auto mb-4" />}

          {isLoading ? <Spinner /> : children}
          <span className="flex justify-end mt-2 text-sm text-neutral-400">
            {version}
          </span>
        </div>
      </div>
    </>
  );
};

export default Wrapper;
