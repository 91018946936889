import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { useAuth } from '@pib/auth';

import { version } from '../../../package.json';
import { useMedium } from '../../utils';
import Icon from '../Icon';
import styles from './index.module.css';

const Sidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isSmallerThenMedium = useMedium();
  const { logout, isLoading: isLoadingLogout, currentUser } = useAuth();
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    setCollapsed(isSmallerThenMedium);
  }, [isSmallerThenMedium]);

  return (
    <nav className="bg-tertiary-500 flex flex-col">
      <div className={clsx(styles.navItem, 'flex flex-col')}>
        <div>{t('sidebar.welcome')},</div>
        <div className="font-semibold">
          {collapsed
            ? currentUser?.name
                ?.split(' ')
                .map(part => part.slice(0, 1).toUpperCase())
            : currentUser?.name ?? ''}
        </div>
      </div>
      <Link
        to="/dashboard"
        className={clsx(
          styles.navItem,
          location.pathname === '/dashboard' && styles.navItem__active,
          !collapsed && styles.navItem__collapsed,
        )}
      >
        <Icon type="check" className="icon-fixed-width mr-2" />
        {!collapsed && <span>{t('sidebar.dashboard')}</span>}
      </Link>
      <Link
        to="/employees"
        className={clsx(
          styles.navItem,
          location.pathname === '/employees' && styles.navItem__active,
          !collapsed && styles.navItem__collapsed,
        )}
      >
        <Icon type="users" className="icon-fixed-width mr-2" />
        {!collapsed && <span>{t('sidebar.employees')}</span>}
      </Link>
      <Link
        to="/templates"
        className={clsx(
          styles.navItem,
          location.pathname === '/templates' && styles.navItem__active,
          !collapsed && styles.navItem__collapsed,
        )}
      >
        <Icon type="file-alt" className="icon-fixed-width mr-2" />
        {!collapsed && <span>{t('sidebar.importData')}</span>}
      </Link>
      <div
        className={clsx(
          styles.navItem,
          'cursor-pointer',
          'mt-6',
          location.pathname === '/b' && styles.navItem__active,
          !collapsed && styles.navItem__collapsed,
        )}
        onClick={() => !isLoadingLogout && logout()}
      >
        <Icon
          type={isLoadingLogout ? 'circle-notch' : 'sign-out-alt'}
          spin={isLoadingLogout}
          className="icon-fixed-width mr-2"
        />
        {!collapsed && <span>{t('sidebar.logout')}</span>}
      </div>
      <div className="flex justify-between items-center mt-auto px-2 mb-2">
        <div className="text-sm text-neutral-100">{version}</div>
        <div
          className="cursor-pointer text-xl"
          onClick={() => setCollapsed(!collapsed)}
        >
          <Icon
            type={collapsed ? 'chevron-circle-right' : 'chevron-circle-left'}
          />
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
