import type { ReactNode } from 'react';
import { Component } from 'react';
import styled from 'styled-components';

import { media } from 'modules/ui';

const Name = styled.h1`
  font-size: 3.7rem;
  ${media.sm`font-size: 2.8rem`};
  color: ${p => p.theme.primary};
`;

interface Props {
  children: ReactNode;
}

class ErrorBoundary extends Component<Props> {
  state = {
    hasError: false,
  };

  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center">
          <Name>Partner In Benefits</Name>
          <img
            src="https://cdn.dribbble.com/users/1078347/screenshots/2799566/oops.png"
            alt="something went wrong"
          />
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
