import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ActionLink = styled(Link)`
  color: ${p => p.theme.primary};
  margin-top: 10px;
  text-decoration: none;
`;

export const ActionLinkText = styled.span`
  position: relative;
  &:after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 1px;
    width: 0;
    opacity: 0;
    transition: all 0.5s;
    background-color: ${p => p.theme.primary};
  }
  &:hover:after {
    width: 100%;
    opacity: 1;
  }
`;
