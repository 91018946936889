import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import omit from 'object.omit';
import styled from 'styled-components';

import type { StyleProps } from './props';
import { marginProps, paddingProps, propNames } from './props';
import type { Theme } from './theme';
import theme from './theme';

library.add(fas);

const StyledIcon = styled(props => (
  <FontAwesomeIcon {...omit(props, propNames)} />
))`
  cursor: pointer;
  ${marginProps};
  ${paddingProps};
  ${p => p.fontSize && `font-size: ${p.fontSize}rem`};
`;

interface Props extends StyleProps {
  type: string | string[];
  className?: string;
  color?: keyof Theme | string | null;
  size?: string;
  spin?: boolean;
  onClick?: () => void;
}

const Icon = ({ type, className, color, size, ...rest }: Props) => {
  let themeColor = color;
  if (color && Object.keys(theme).find(key => key === color)) {
    themeColor = theme[color] as keyof Theme;
  }
  return (
    <StyledIcon
      className={className}
      color={themeColor}
      icon={type}
      size={size}
      {...rest}
    />
  );
};

export default Icon;
