import type { FC } from 'react';
import clsx from 'clsx';

interface Props {
  name: string;
  className?: string;
}

const Avatar: FC<Props> = ({ name, className }) => {
  return (
    <div className={clsx('flex items-center relative', className)}>
      <div className="w-10 h-10 relative overflow-hidden flex justify-center items-center">
        {name
          .split(' ')
          .slice(0, 3)
          .map(word => word.slice(0, 1).toUpperCase())}
      </div>
      <div className="absolute inset-0 rounded-full border border-neutral-100" />
    </div>
  );
};

export default Avatar;
