export var ApiFieldType;
(function (ApiFieldType) {
    ApiFieldType["Boolean"] = "boolean";
    ApiFieldType["Calculated"] = "calculated";
    ApiFieldType["Concatted"] = "concatted";
    ApiFieldType["Date"] = "date";
    ApiFieldType["Decimal"] = "decimal";
    ApiFieldType["Integer"] = "integer";
    ApiFieldType["Text"] = "text";
    ApiFieldType["MultiCalculated"] = "multicalculated";
    ApiFieldType["MultiText"] = "multitext";
    ApiFieldType["MultiValue"] = "multivalue";
    ApiFieldType["Field"] = "field";
    ApiFieldType["ImportDate"] = "importDate";
})(ApiFieldType || (ApiFieldType = {}));
export var ApiTaskType;
(function (ApiTaskType) {
    ApiTaskType["Data"] = "data";
    ApiTaskType["Add"] = "add";
    ApiTaskType["Message"] = "message";
    ApiTaskType["Email"] = "email";
    ApiTaskType["Employee"] = "employee";
    ApiTaskType["Remove"] = "remove";
    ApiTaskType["Notification"] = "notification";
    ApiTaskType["FamilyPolicyJoin"] = "familyPolicyJoin";
    ApiTaskType["PolicyJoin"] = "policyJoin";
    ApiTaskType["PolicyChange"] = "policyChange";
    ApiTaskType["PolicyLeave"] = "policyLeave";
})(ApiTaskType || (ApiTaskType = {}));
export var ApiFormatType;
(function (ApiFormatType) {
    ApiFormatType["Currency"] = "currency";
    ApiFormatType["DayMonthYear"] = "dayMonthYear";
    ApiFormatType["MaxInsuredCurrency"] = "maxInsuredCurrency";
})(ApiFormatType || (ApiFormatType = {}));
