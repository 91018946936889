import PropTypes from 'prop-types';
import styled from 'styled-components';

import { hex2rgb } from './helpers';

const Outer = styled.div`
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  border: 1px solid ${p => hex2rgb(p.theme.primary, 0.2)};
  border-radius: 50%;
  transition: border-color 0.3s ease-in;
  position: relative;
`;

const Inner = styled.div`
  width: 10px;
  height: 10px;
  border: 1px solid ${p => hex2rgb(p.theme.primary, 0.2)};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${p => p.theme.primary};
`;

export const ItemTitle = styled.span`
  margin-bottom: 5px;
  font-weight: 700;
  transition: color 0.1s ease-in;
`;

export const Item = styled.div`
  border-bottom: 1px solid ${p => p.theme.border};
  display: flex;
  margin-bottom: 10px;
  padding: 10px 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in;
  &:hover {
    background-color: ${p => hex2rgb(p.theme.primary, 0.2)};
  }
  &:hover ${Outer} {
    border-color: ${p => p.theme.primary};
  }
`;

export const Radio = ({ selected }) => <Outer>{selected && <Inner />}</Outer>;
Radio.propTypes = {
  selected: PropTypes.bool,
};
