class DashboardApi {
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getEmployerDashboardData() {
        return this.fetchService.get('/portal/dashboard/employer');
    }
    getEmployeeDashboardData() {
        return this.fetchService.get('/portal/dashboard/employee');
    }
    cancelChoices(policyId) {
        return this.fetchService.delete(`/portal/dashboard/choices/${policyId}`);
    }
}
export default DashboardApi;
