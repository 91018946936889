import { Component } from 'react';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center">
          <h1 className="text-5xl text-primary-500">Partner In Benefits</h1>
          <img
            src="https://cdn.dribbble.com/users/1078347/screenshots/2799566/oops.png"
            alt="oops"
          />
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
