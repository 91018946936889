export interface Theme {
  black: string;
  text: string;
  textLight: string;
  primary: string;
  primaryLight: string;
  primaryDark: string;
  secondary: string;
  white: string;
  border: string;
  grey: string;
  gradient: string;
  confirm: string;
  error: string;
  edit: string;
  sidebarIcon: string;
  sidebarText: string;
  [color: string]: string;
}

const theme: Theme = {
  black: '#000',
  text: '#737885',
  textLight: '#b0b0b0',
  primary: '#6a93cd',
  primaryLight: 'rgba(106,147,205,0.8)',
  primaryDark: '#164290',
  secondary: '#ff8700',
  white: '#FEFFFE',
  border: '#eee',
  grey: '#F7F7F9',
  gradient: 'radial-gradient(circle, #EEEEEE 0%, #0F5FA2 0%, #639DD3 100%)',
  confirm: '#5FB770',
  error: '#F23054',
  edit: '#FFD700',
  sidebarIcon: '#aaa',
  sidebarText: '#737885',
};

export default theme;
