export default ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 642.57 280.56"
    className={className}
  >
    <title>myben</title>
    <path
      fill="#ffffff"
      className="cls-1"
      d="M138.5,115.87q2.7,4,6.67,9.73T153.83,138q4.68,6.67,9.65,13.62l9.58,13.42q4.62,6.45,8.45,11.85l6.24,8.8,4.62-6.67q3.33-4.83,7.8-11.22T209.89,154l10.29-14.61q5-7.17,9.51-13.35T237,115.87V221.76H226.78V149.51a25.51,25.51,0,0,1,.15-2.69c.09-.95.14-1.75.14-2.42q-2.41,3.42-5.75,8.17t-7,10.14q-3.69,5.4-7.52,11t-7.38,10.86q-3.55,5.25-6.6,9.8l-5,7.52q-2-2.55-5.39-7.31t-7.45-10.65q-4-5.88-8.45-12.2t-8.16-11.85q-3.77-5.55-6.67-9.73c-1.94-2.79-3.24-4.7-3.9-5.75a17,17,0,0,0,.21,2.42,18.32,18.32,0,0,1,.21,2.69v72.25H138.5Z"
    />
    <path
      fill="#ffffff"
      className="cls-1"
      d="M260.3,249h-8.23q1.85-3.82,3.33-7.17t2.91-6.74c.95-2.27,2-4.66,3.05-7.17s2.35-5.32,3.77-8.44q-6.54-13.06-12.78-27.18T239.58,165H248q4.26,8.5,7.38,15.32t5.61,12.42q2.47,5.61,4.47,10.44c1.32,3.21,2.65,6.34,4,9.36,1.33-3,2.63-6.15,3.91-9.36s2.74-6.7,4.4-10.44,3.52-7.87,5.6-12.42,4.54-9.65,7.38-15.32h8.38Z"
    />
    <path
      fill="#ffffff"
      className="cls-1"
      d="M312.53,221.76V118.29h28.25a59.7,59.7,0,0,1,13.91,1.49,29.5,29.5,0,0,1,10.64,4.68,20.63,20.63,0,0,1,6.74,8.38,29.82,29.82,0,0,1,2.35,12.42,38.6,38.6,0,0,1-.78,7.73,24.87,24.87,0,0,1-2.56,7,20.12,20.12,0,0,1-4.61,5.6,19.43,19.43,0,0,1-7.1,3.69,31.26,31.26,0,0,1,7.17,2.42,21.64,21.64,0,0,1,11.07,11.85,26.72,26.72,0,0,1,1.77,10.15,26.42,26.42,0,0,1-2.91,12.84,25.75,25.75,0,0,1-7.66,8.73,32,32,0,0,1-11,5,53.15,53.15,0,0,1-12.92,1.56ZM329.85,134v28.11h13.06q7.23,0,10.71-4.26a15.65,15.65,0,0,0,3.48-10.22q0-7-4.26-10.29T341.35,134Zm0,42.87V206h13.62a27.36,27.36,0,0,0,7.24-.92,19,19,0,0,0,6-2.69,12.29,12.29,0,0,0,4-4.55,14,14,0,0,0,1.42-6.46,12.88,12.88,0,0,0-1.71-6.88,13.3,13.3,0,0,0-4.54-4.47,19.63,19.63,0,0,0-6.46-2.41,38.81,38.81,0,0,0-7.45-.71Z"
    />
    <path
      fill="#ffffff"
      className="cls-1"
      d="M438.18,214.81a32.08,32.08,0,0,1-10.65,6.24,37.18,37.18,0,0,1-12.77,2.27,32.06,32.06,0,0,1-12.56-2.48A31.63,31.63,0,0,1,392,214,33.14,33.14,0,0,1,385.09,204a30.84,30.84,0,0,1,0-24.56A33.31,33.31,0,0,1,392,169.31a32.22,32.22,0,0,1,10.07-6.81A30.41,30.41,0,0,1,414.19,160a28,28,0,0,1,18,6.17,33.54,33.54,0,0,1,11,16.25q-4.26,1.58-9.87,3.76l-11.71,4.62q-6.1,2.42-12.21,4.89t-11.21,4.48a19.15,19.15,0,0,0,6.67,7.09,17.65,17.65,0,0,0,9.65,2.7,22,22,0,0,0,7.46-1.35A17.46,17.46,0,0,0,428.1,205Zm-11.93-35.49a10.29,10.29,0,0,0-5.11-4.61,16.44,16.44,0,0,0-6.67-1.49,17.73,17.73,0,0,0-7.23,1.49,18,18,0,0,0-5.82,4,19.5,19.5,0,0,0-3.84,5.68,16.2,16.2,0,0,0-1.42,6.6v.57l7.6-3.05q4-1.64,8.09-3.34t7.73-3.19C422,181,424.27,180.08,426.25,179.32Z"
    />
    <path
      fill="#ffffff"
      className="cls-1"
      d="M446.3,161.44h11.92q.28,5.1.64,8.3t.64,7.17a30.55,30.55,0,0,1,9.22-12.35q6-4.68,14.91-4.68,9.22,0,14.69,5A18,18,0,0,1,504.07,178v43.72H490.58V184.43a12.31,12.31,0,0,0-3.12-7.59q-2.83-3.19-8.8-3.48a19.07,19.07,0,0,0-7.59,1.49,18.11,18.11,0,0,0-6,4,17.63,17.63,0,0,0-3.84,6.11,21.34,21.34,0,0,0-1.34,7.66v29.1H446.3Z"
    />
  </svg>
);
