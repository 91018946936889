import { css } from 'styled-components';

export const breakpoints: Record<string, number> = {
  lg: 1200,
  md: 768,
  sm: 500,
  xs: 0,
};

export default Object.keys(breakpoints).reduce((accumulator, label) => {
  const emSize = breakpoints[label] / 16;
  accumulator[label] = (literals: TemplateStringsArray, ...args: any[]) =>
    css`
      @media (max-width: ${emSize}em) {
        ${css(literals, ...args)};
      }
    `.join('');
  return accumulator;
}, {} as Record<keyof typeof breakpoints, (l: TemplateStringsArray, ...p: any[]) => string>);
