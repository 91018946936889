import { FetchService } from '@pib/api';
class AuthService {
    constructor() {
        this.fetchService = new FetchService();
    }
    getToken(type, code, tokenType) {
        return this.fetchService.post(`/token/${tokenType}/${type}`, {
            grantType: 'auth_code',
            authorizationCode: code,
        });
    }
    logout() {
        return this.fetchService.post('/token/logout');
    }
}
export default AuthService;
