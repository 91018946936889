import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from './icon';
import { RadioInput } from './inputs';
import { colorProps } from './props';

export const Container = styled.div`
  max-width: 1024px;
  margin: auto;
  padding: 0 15px;
`;

export const Wrapper = styled.div`
  background-color: #f7f7f9;
  flex: 1 0 auto;
  position: relative;
`;

const Link = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  display: flex;
  align-items: center;
  color: ${p => p.theme.primary};
  text-decoration: none;
  &:hover {
    opacity: 0.8;
    text-decoration: underline;
  }
`;

export const ExternalLink = ({
  href,
  children,
  className,
  showIcon = true,
}) => (
  <Link
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={className}
  >
    {children}
    {showIcon && <Icon type="external-link-alt" size="xs" marginLeft={4} />}
  </Link>
);
ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
  showIcon: PropTypes.bool,
  className: PropTypes.string,
};

export const FormErrorMessage = styled.div`
  margin-top: 4px;
  margin-left: 8px;
  font-style: italic;
  color: ${p => p.theme.error};
  ${colorProps('color')};
`;

export const FormLabel = styled.label`
  margin-bottom: 4px;
  font-weight: 600;
`;

export const RadioList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const RadioListItem = styled.li`
  list-style-type: none;
  margin: 10px 0;
  padding: 0;
  position: relative;
  &:hover > ${RadioInput.Label} {
    color: ${p => p.theme.secondary};
    &::before {
      border: 0.5rem solid ${p => p.theme.secondary};
      margin-right: 2rem;
    }
  }
`;
