var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isObject } from './utils';
const __DEV__ = process.env.NODE_ENV === 'development';
class FetchService {
    constructor(providers) {
        this.providers = providers;
        this.headers = {
            'Content-type': 'application/json',
            Accept: 'application/json',
        };
        this.credentials = 'include';
        this.apiUrl = process.env.REACT_APP_API_URL;
    }
    get(url, { resultAsBlob = false } = {}) {
        return this.request({
            url,
            params: { method: 'GET' },
            options: { resultAsBlob },
        });
    }
    post(url, body) {
        return this.request({
            url,
            params: {
                body: body instanceof FormData ? body : JSON.stringify(body),
                method: 'POST',
            },
        });
    }
    put(url, body) {
        return this.request({
            url,
            params: {
                body: JSON.stringify(body),
                method: 'PUT',
            },
        });
    }
    delete(url, body) {
        return this.request({
            url,
            params: {
                body: JSON.stringify(body),
                method: 'DELETE',
            },
        });
    }
    request({ url, params = {}, options = {}, headers, }) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.apiUrl) {
                throw new Error('The api url is undefined');
            }
            const requestHeaders = Object.assign(Object.assign({}, (headers || this.headers)), { 'Accept-Language': (_b = (_a = this.providers) === null || _a === void 0 ? void 0 : _a.getLanguage) === null || _b === void 0 ? void 0 : _b.call(_a) });
            if ((params === null || params === void 0 ? void 0 : params.body) instanceof FormData) {
                delete requestHeaders['Content-type'];
            }
            if (__DEV__) {
                console.log('api request', { url, params });
            }
            const response = yield fetch(this.apiUrl + url, Object.assign({ headers: requestHeaders, credentials: this.credentials }, params));
            return this.handleResponse(response, options);
        });
    }
    handleResponse(res, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!res.ok) {
                const text = yield res.text();
                try {
                    const parsedError = JSON.parse(text);
                    let errors = [];
                    let msg = parsedError;
                    const { extraProperties } = parsedError;
                    if (isObject(parsedError)) {
                        errors = (_a = parsedError.errors) !== null && _a !== void 0 ? _a : [];
                        msg = parsedError.message;
                    }
                    if (__DEV__) {
                        console.log('api error', parsedError);
                    }
                    return Promise.reject({
                        status: res.status,
                        message: msg || '',
                        url: res.url,
                        errors: errors || [],
                        extraProperties,
                    });
                }
                catch (err) {
                    return Promise.reject({
                        status: res.status,
                        message: text,
                        url: res.url,
                        errors: [],
                    });
                }
            }
            if (options.resultAsBlob) {
                return res.blob();
            }
            const text = yield res.text();
            try {
                return JSON.parse(text);
            }
            catch (_b) {
                return text;
            }
        });
    }
}
export default FetchService;
