class ImportApi {
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    importEmployersCompanyTemplate({ companyId, templateId, files, month, year, }) {
        const qs = this.getQueryString(month, year);
        const body = this.getBody(files);
        return this.fetchService.post(`/employers/companies/${companyId}/templates/${templateId}/import?${qs}`, body);
    }
    simulateImportEmployersCompanyTemplate({ companyId, templateId, files, month, year, }) {
        const qs = this.getQueryString(month, year);
        const body = this.getBody(files);
        return this.fetchService.post(`/employers/companies/${companyId}/templates/${templateId}/import/simulate?${qs}`, body);
    }
    getQueryString(month, year) {
        const params = new URLSearchParams();
        params.append('month', String(month));
        params.append('year', String(year));
        return params.toString();
    }
    getBody(files) {
        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });
        return formData;
    }
}
export default ImportApi;
