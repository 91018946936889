import clsx from 'clsx';

export default ({ className }: { className?: string }) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1074.392 584.231"
    className={clsx(className, 'w-full max-w-httpErrorIllustration mt-6')}
  >
    <ellipse cx="540.643" cy="549.309" rx="527.5" ry="34.922" fill="#f2f2f2" />
    <path
      d="M520.426 167.01c-85.944 0-147.651 55.14-147.651 183.798 0 145.813 61.707 184.41 147.65 184.41s151.328-42.273 151.328-184.41c0-151.94-65.383-183.798-151.327-183.798zm.565 319.808c-59.527 0-90.626-34.923-90.626-135.916 0-89.112 32.372-136.105 91.899-136.105s91.899 30.868 91.899 136.105c0 98.447-33.646 135.916-93.172 135.916z"
      fill="#2f2e41"
    />
    <path
      d="M321.311 433.517h-35.534v-104.64a20.956 20.956 0 00-20.956-20.956h-8.326a20.956 20.956 0 00-20.956 20.956v104.64h-100.23a11.963 11.963 0 01-10.578-17.552l106.083-200.78a20.956 20.956 0 00-9.58-28.74l-6.263-2.957a20.956 20.956 0 00-27.43 9.07L58.162 434.591a28.416 28.416 0 00-3.357 13.396 28.416 28.416 0 0028.416 28.416H235.54v66.167a25.119 25.119 0 0025.119 25.12 25.119 25.119 0 0025.119-25.12v-66.167h35.534a21.443 21.443 0 0021.443-21.443 21.443 21.443 0 00-21.443-21.443zM979.308 433.517h-35.534v-104.64a20.956 20.956 0 00-20.956-20.956h-8.327a20.956 20.956 0 00-20.956 20.956v104.64h-100.23a11.963 11.963 0 01-10.577-17.552l106.082-200.78a20.956 20.956 0 00-9.58-28.74l-6.263-2.957a20.956 20.956 0 00-27.429 9.07L716.157 434.591a28.416 28.416 0 00-3.356 13.396 28.416 28.416 0 0028.416 28.416h152.318v66.167a25.119 25.119 0 0025.12 25.12 25.119 25.119 0 0025.118-25.12v-66.167h35.535a21.443 21.443 0 0021.443-21.443 21.443 21.443 0 00-21.443-21.443z"
      fill="#2B8BDC"
    />
    <path
      d="M331.114 421.264H295.58v-104.64a20.956 20.956 0 00-20.956-20.956h-8.327a20.956 20.956 0 00-20.956 20.956v104.64h-100.23a11.963 11.963 0 01-10.577-17.552l106.082-200.78a20.956 20.956 0 00-9.58-28.74l-6.263-2.957a20.956 20.956 0 00-27.429 9.07L67.963 422.338a28.416 28.416 0 00-3.356 13.396h0a28.416 28.416 0 0028.416 28.416H245.34v66.167a25.119 25.119 0 0025.12 25.119h0a25.119 25.119 0 0025.119-25.12V464.15h35.534a21.443 21.443 0 0021.443-21.443h0a21.443 21.443 0 00-21.443-21.443zM997.688 421.264h-35.535v-104.64a20.956 20.956 0 00-20.956-20.956h-8.326a20.956 20.956 0 00-20.956 20.956v104.64h-100.23a11.963 11.963 0 01-10.577-17.552l106.082-200.78a20.956 20.956 0 00-9.58-28.74l-6.263-2.957a20.956 20.956 0 00-27.43 9.07l-129.38 242.033a28.416 28.416 0 00-3.356 13.396h0a28.416 28.416 0 0028.415 28.416h152.32v66.167a25.119 25.119 0 0025.118 25.119h0a25.119 25.119 0 0025.12-25.12V464.15h35.534a21.443 21.443 0 0021.443-21.443h0a21.443 21.443 0 00-21.443-21.443zM540.03 155.982c-85.943 0-147.65 55.14-147.65 183.798 0 145.813 61.707 184.41 147.65 184.41s151.328-42.273 151.328-184.41c0-151.94-65.383-183.798-151.327-183.798zm.566 319.808c-59.527 0-90.626-34.923-90.626-135.916 0-89.112 32.372-136.105 91.899-136.105s91.899 30.868 91.899 136.105c0 98.447-33.645 135.916-93.172 135.916z"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <circle cx="471.141" cy="18.25" r="12.901" fill="#2f2e41" />
    <ellipse cx="502.607" cy="46.885" rx="36.186" ry="46.885" fill="#2f2e41" />
    <path
      d="M502.607 79.61c-18.127 0-33.14-17.27-35.775-39.805a60.976 60.976 0 00-.41 7.08c0 25.893 16.2 46.885 36.185 46.885s36.187-20.991 36.187-46.885a60.976 60.976 0 00-.41-7.08c-2.635 22.534-17.649 39.805-35.777 39.805z"
      opacity=".1"
    />
    <path
      d="M576.242 184.189c-.777 3.193-4.128 5.75-7.839 7.538-7.801 3.756-17.425 4.878-26.76 5.254a45.176 45.176 0 01-7.144-.132 20.537 20.537 0 01-12.25-5.632 1.68 1.68 0 01.043-2.843c4.97-5.46 13.263-8.806 21.617-11.217 6.334-1.827 17.38-6.56 24.29-5.922 6.691.62 9.024 8.922 8.043 12.954z"
      fill="#3f3d56"
    />
    <path
      d="M576.242 184.189c-.777 3.193-4.128 5.75-7.839 7.538-7.801 3.756-17.425 4.878-26.76 5.254a45.176 45.176 0 01-7.144-.132 20.537 20.537 0 01-12.25-5.632 1.68 1.68 0 01.043-2.843c4.97-5.46 13.263-8.806 21.617-11.217 6.334-1.827 17.38-6.56 24.29-5.922 6.691.62 9.024 8.922 8.043 12.954z"
      opacity=".1"
    />
    <path
      d="M477.044 160.321a19.77 19.77 0 00-1.199 15.075 26.34 26.34 0 008.83 12.497c10.094 8.092 23.987 9.205 36.924 9.093a284.65 284.65 0 0033.905-2.324 40.538 40.538 0 0011.002-2.554c4.222-1.827 7.933-5.178 9.436-9.526s.436-9.673-3.134-12.574c-3.137-2.55-7.463-2.9-11.498-3.143l-23.087-1.388c2.28-2.308 5.318-3.614 8.095-5.292 3.686-2.226 6.134-5.965 8.814-9.335a129.001 129.001 0 0113.438-13.817c.752 4.31 3.478 7.85 6.688 10.824s6.908 5.369 10.243 8.2c8.079 6.859 13.896 16.167 22.393 22.5a43.829 43.829 0 0016.048-8.011l-3.302-5.982a3.94 3.94 0 00-1.244-1.553c-.935-.575-2.14-.278-3.225-.441-2.901-.435-4.168-3.784-5.306-6.487-3.125-7.422-9.108-13.18-14.218-19.404a98.009 98.009 0 01-9.996-14.723c-1.717-3.102-3.288-6.331-5.618-9.003s-5.593-4.773-9.138-4.78c-3.132-.007-6.021 1.583-8.714 3.182a230.477 230.477 0 00-23.63 16.1c-3.944 3.061-7.864 6.296-12.49 8.173-1.947.79-4 1.33-5.869 2.292-3.273 1.685-5.757 4.535-8.431 7.065-9.538 9.023-22.596 14.869-35.717 15.336z"
      fill="#3f3d56"
    />
    <path
      d="M525.32 96.098a23.774 23.774 0 01-1.734 8.033 10.045 10.045 0 01-5.768 5.573 12.375 12.375 0 01-5.623.183 10.882 10.882 0 01-4.581-1.561c-2.165-1.489-3.245-4.144-3.638-6.743-.393-2.596-.217-5.249-.469-7.864a42.944 42.944 0 00-1.202-6.255c-.17-.683-.343-1.363-.513-2.042-.166-.68-.33-1.36-.481-2.04a44.287 44.287 0 01-.378-1.925 28.716 28.716 0 01-.233-1.643 18.423 18.423 0 00-.808-4.811 14.607 14.607 0 00-1.687-2.854c-.286-.409-.563-.818-.818-1.243a5.89 5.89 0 01-.972-3.748 3.286 3.286 0 01.148-.6c.025-.076.053-.152.085-.224a1.802 1.802 0 001.23-1.149 3.433 3.433 0 00-.148-1.777 11.318 11.318 0 00-.96-2.288c-.264-.478-1.16-1.34-1.167-1.888-.013-1.101 2.14-1.989 3.011-2.423a16.796 16.796 0 018.597-1.743c1.904.129 3.968.714 5.019 2.31.944 1.438.818 3.3 1.22 4.971a1.47 1.47 0 00.3.664 1.341 1.341 0 00.74.34 4.55 4.55 0 001.415.057h.003a2.931 2.931 0 00.371-.054 4.957 4.957 0 002.033-.878q.586-.156 1.18-.255a.251.251 0 00.047-.01c1.577 4.976 1.781 10.31 3.072 15.375.638 2.52 1.554 5.01 1.683 7.603.01.113.013.23.016.346.019.834-.047 1.674-.013 2.504a6.981 6.981 0 00.126 1.114 15.611 15.611 0 00.525 1.744l.01.028c.06.182.116.368.164.554.034.135.066.267.09.402l.004.01a14.938 14.938 0 01.104 4.207z"
      fill="#fbbebe"
    />
    <circle cx="503.237" cy="44.997" r="18.565" fill="#fbbebe" />
    <path
      d="M621.103 146.148a30.445 30.445 0 00-5.236-14.103q.722 4.295 1.448 8.587a3.214 3.214 0 01-3.367-1.035 10.337 10.337 0 01-1.766-3.276 67.466 67.466 0 00-8.21-14.735c-11.818-.985-23.501-5.884-33.895-11.596-10.396-5.708-20.125-12.552-30.383-18.502a43.573 43.573 0 00-5.545-2.832c-3.21-1.287-6.812-1.954-9.855-3.467-.205-.101-.41-.208-.61-.318a12.578 12.578 0 01-1.945-1.306 10.344 10.344 0 01-.931-.856 20.351 20.351 0 01-3.56-5.953c-1.632-3.613-2.215-7.97-3.845-11.583a11.203 11.203 0 012.502-1.765h.003c.132-.07.265-.136.4-.199a11.94 11.94 0 012.004-.733q.586-.156 1.18-.255a.251.251 0 00.047-.01 11.566 11.566 0 015.491.435c2.587.875 4.767 2.621 6.942 4.273a114.02 114.02 0 0110.148 8.05c1.793 1.717 3.43 3.605 5.358 5.166a42.144 42.144 0 005.057 3.351q15.656 9.327 31.315 18.65c3.534 2.105 7.07 4.21 10.526 6.438 5.248 3.386 10.308 7.055 15.365 10.72q4.47 3.239 8.936 6.48a9.728 9.728 0 012.533 2.34 8.472 8.472 0 011.123 3.434 31.387 31.387 0 01-1.23 14.6z"
      fill="#fbbebe"
    />
    <path
      d="M529.923 110.06c-1.252 5.616-6.929 9.012-9.896 13.945-3.688 6.124-2.184 13.241-.8 20.255q-3.794 3.271-7.592 6.542c-1.397 1.199-2.794 2.404-4.298 3.465a57.35 57.35 0 01-6.86 3.94q-3.36 1.727-6.721 3.457a32.128 32.128 0 01-6.58 2.788c-4.414 1.133-9.103.34-13.47-.972a6.088 6.088 0 01-1.473-.601 2.394 2.394 0 01-.699-.633 3.91 3.91 0 01-.443-2.539c.443-7.35 2.24-14.547 4.03-21.69a85.26 85.26 0 013.849-12.577 85.077 85.077 0 015.415-10.15 68.368 68.368 0 017.93-11.514 18.479 18.479 0 003.675-4.739c1.117-2.549.686-5.472.913-8.247a17.148 17.148 0 011.633-6.007v-.003a17.093 17.093 0 011.743-2.883l.916 2.13q.302.7.597 1.395.387.896.768 1.787l2.197 5.113a21.59 21.59 0 003.335 5.944 6.5 6.5 0 0011.123-.853 21.261 21.261 0 002.272-6.013 19.215 19.215 0 00.252-7.835c-.758-5.01-2.889-10.126-4.437-14.78a14.945 14.945 0 01-1.08-4.87 3.351 3.351 0 01.057-.56l.029-.146a11.94 11.94 0 012.004-.733q.585-.155 1.18-.255 2.044 11.064 4.094 22.128c.031.173.066.346.097.522.196 1.057.394 2.114.614 3.169.198.956.412 1.91.651 2.857l.01.028c.082.321.167.639.255.957l.003.009c.242.868.513 1.73.818 2.58 1.85 5.12 5.076 10.274 3.89 15.589z"
      fill="#2B8BDC"
    />
    <path
      d="M605.267 188.993a6.583 6.583 0 00.61 3.143c1.162 2.123 3.95 2.606 6.363 2.805a188.377 188.377 0 0042.265-1.288 4.886 4.886 0 002.152-.668c1.99-1.395.763-4.795-1.41-5.883s-4.751-.826-7.135-1.298a22.48 22.48 0 01-6.678-2.896q-7.252-4.167-14.293-8.688c-2.794-1.795-6.093-3.71-9.24-2.646-8.525 2.884-12.69 8.222-12.634 17.419z"
      fill="#3f3d56"
    />
    <path
      d="M501.383 82.99v.002a7.75 7.75 0 01-.072.39c-.035.215-.076.432-.116.646a77.708 77.708 0 01-1.42 5.962c-1.51 5.46-3.533 10.831-5.54 16.124L478.07 148.69c-1.36 3.574-2.716 7.152-4.26 10.654-.517 1.168-1.048 2.344-1.577 3.534-1.894 4.257-3.713 8.653-4.317 13.184a27.45 27.45 0 00-.192 9.04c.604 2.97 2.407 5.872 5.23 6.97 1.378.538 3.35 1.259 2.973 2.69-.204.787-1.098 1.171-1.9 1.303a7.312 7.312 0 01-5.966-1.718c-1.508-1.337-2.666-3.417-4.67-3.64-1.388-.151-2.665.68-3.936 1.261-5.186 2.37-11.228.712-16.674-.972.427-2.206 2.643-3.66 4.842-4.1 2.2-.444 4.475-.129 6.718-.189a3.51 3.51 0 002.042-.525 3.705 3.705 0 001.102-1.882 78.964 78.964 0 003.212-13.146c.724-4.667 1.026-9.406 2.058-14.016.803-3.596 2.042-7.08 3.106-10.61a224.682 224.682 0 005.06-22.077 78.02 78.02 0 001.425-9.367c.18-2.612.095-5.236.346-7.839a60.888 60.888 0 012.112-9.996l2.885-10.69c1.426-5.27 2.955-10.745 6.567-14.842a13.962 13.962 0 0110.029-4.79 9.882 9.882 0 012.13.23c.116.029.233.057.349.089a8.634 8.634 0 012.174.9c1.114-.709 1.68-.46 2.42.632a6.973 6.973 0 01.881 3.795 52.424 52.424 0 01-.856 10.415z"
      fill="#fbbebe"
    />
    <path
      d="M502.607 87.162l-.047.047-.255.255-2.53 2.526-1.24-5.207-4.25-17.854a9.882 9.882 0 012.13.23 3.286 3.286 0 01.148-.602l.201.69 1.441 4.902 2.728 9.308.45 1.532v.003z"
      fill="#2B8BDC"
    />
    <path
      d="M518.661 30.203a12.582 12.582 0 01-3.7 8.896 12.314 12.314 0 01-1.36 1.176 12.528 12.528 0 01-7.536 2.524h-15.096a12.59 12.59 0 010-25.18h15.096a12.63 12.63 0 0112.596 12.584z"
      fill="#2f2e41"
    />
    <circle cx="532.815" cy="18.25" r="12.901" fill="#2f2e41" />
    <path
      d="M532.5 5.35c-.158 0-.315.006-.472.011a12.898 12.898 0 010 25.779c.157.005.314.012.472.012a12.901 12.901 0 100-25.803zM471.141 5.35c.158 0 .315.006.472.011a12.898 12.898 0 000 25.779c-.157.005-.314.012-.472.012a12.901 12.901 0 010-25.803zM513.6 40.275a12.528 12.528 0 01-7.535 2.524h-15.096a12.528 12.528 0 01-7.536-2.524z"
      opacity=".1"
    />
    <path
      d="M611.086 133.756s3.252 9.372 6.229 6.876l-2.373-12.25z"
      fill="#fbbebe"
    />
    <path
      d="M1006.864 419.55a20.813 20.813 0 102.771-39.916l.521 10.713-5.068-9.18a20.734 20.734 0 00-10.683 11.722 20.408 20.408 0 00-1.198 5.63 20.809 20.809 0 0013.657 21.03z"
      fill="#57b894"
    />
    <path
      d="M1031.941 543.793c-1.789-9.11 5.963-17.187 13.621-22.436s16.605-10.408 19.218-19.317c3.755-12.804-7.43-24.53-16.136-34.642a125.3 125.3 0 01-16.523-24.557c-1.812-3.533-3.476-7.226-3.953-11.167-.686-5.675 1.137-11.323 2.974-16.736q9.18-27.052 19.629-53.65"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <path
      d="M1007.72 416.792a20.813 20.813 0 102.773-39.916l.52 10.713-5.068-9.18a20.734 20.734 0 00-10.683 11.722 20.408 20.408 0 00-1.197 5.63 20.809 20.809 0 0013.656 21.03z"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <path
      d="M1029.397 357.588a20.788 20.788 0 0114.98-13.197l1.714 10.183 3.177-10.695a20.81 20.81 0 11-19.87 13.71z"
      fill="#57b894"
    />
    <path
      d="M1030.54 353.91a20.788 20.788 0 0114.98-13.197l1.714 10.184 3.177-10.696a20.81 20.81 0 11-19.87 13.71z"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <path
      d="M1044.99 467.604a20.81 20.81 0 0018.42-37.022l-2.441 8.219-1.732-10.304a.362.362 0 00-.053-.02 20.811 20.811 0 10-14.193 39.127z"
      fill="#57b894"
    />
    <path
      d="M1045.981 463.88a20.81 20.81 0 0018.419-37.023l-2.441 8.22-1.731-10.305a.362.362 0 00-.053-.02 20.811 20.811 0 10-14.194 39.128z"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <path
      d="M1023.324 502.167a20.801 20.801 0 104.01-16.297l9.273 13.956-12.67-7.408a20.616 20.616 0 00-.613 9.749z"
      fill="#57b894"
    />
    <path
      d="M1024.18 499.409a20.801 20.801 0 104.012-16.297l9.272 13.956-12.67-7.407a20.616 20.616 0 00-.613 9.748z"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <path
      d="M9.007 470.249a13.674 13.674 0 101.821-26.225l.343 7.038-3.33-6.032a13.623 13.623 0 00-7.02 7.702 13.409 13.409 0 00-.786 3.7 13.672 13.672 0 008.972 13.817z"
      fill="#57b894"
    />
    <path
      d="M25.484 551.879c-1.176-5.986 3.918-11.292 8.949-14.741s10.91-6.838 12.626-12.692c2.467-8.412-4.881-16.116-10.601-22.76a82.324 82.324 0 01-10.856-16.135 22.21 22.21 0 01-2.597-7.336c-.451-3.729.747-7.44 1.954-10.996q6.03-17.774 12.896-35.25"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <path
      d="M9.57 468.437a13.674 13.674 0 101.822-26.225l.342 7.038-3.33-6.032a13.623 13.623 0 00-7.02 7.702 13.41 13.41 0 00-.786 3.699 13.672 13.672 0 008.973 13.818z"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <path
      d="M23.812 429.539a13.658 13.658 0 019.842-8.671l1.126 6.69 2.088-7.026a13.673 13.673 0 11-13.056 9.007z"
      fill="#57b894"
    />
    <path
      d="M24.563 427.123a13.658 13.658 0 019.842-8.671l1.126 6.69 2.088-7.027a13.673 13.673 0 11-13.056 9.008z"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <path
      d="M34.058 501.822a13.673 13.673 0 0012.101-24.325l-1.604 5.4-1.137-6.77a.238.238 0 00-.035-.013 13.673 13.673 0 10-9.325 25.708z"
      fill="#57b894"
    />
    <path
      d="M34.708 499.374A13.673 13.673 0 0046.81 475.05l-1.604 5.4-1.137-6.77a.238.238 0 00-.035-.013 13.673 13.673 0 10-9.326 25.707z"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <path
      d="M19.822 524.53a13.667 13.667 0 102.635-10.708l6.092 9.17-8.324-4.867a13.545 13.545 0 00-.403 6.405z"
      fill="#57b894"
    />
    <path
      d="M20.385 522.718a13.667 13.667 0 102.635-10.708l6.092 9.17-8.324-4.867a13.545 13.545 0 00-.403 6.405z"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <ellipse cx="480.946" cy="319.115" rx="17" ry="22" fill="#2f2e41" />
    <ellipse cx="573.446" cy="319.615" rx="17" ry="22" fill="#2f2e41" />
    <path
      d="M560.446 384.615c0 9.94-13.88 18-31 18s-31-8.06-31-18c0-8.61 10.41-15.81 24.32-17.57a50.104 50.104 0 016.68-.43 50.699 50.699 0 0111.13 1.2c11.62 2.59 19.87 9.14 19.87 16.8z"
      fill="#2f2e41"
    />
    <ellipse
      cx="484.946"
      cy="314.115"
      rx="17"
      ry="22"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <ellipse
      cx="577.446"
      cy="314.615"
      rx="17"
      ry="22"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <ellipse
      cx="533.446"
      cy="379.615"
      rx="31"
      ry="18"
      fill="none"
      stroke="#3f3d56"
      strokeMiterlimit="10"
    />
    <path
      d="M540.946 369.316a4.937 4.937 0 01-1.32 3.392 4.339 4.339 0 01-3.18 1.407h-10a4.664 4.664 0 01-4.5-4.8 4.905 4.905 0 01.82-2.74 47.02 47.02 0 016.68-.46 47.665 47.665 0 0111.13 1.28 5.067 5.067 0 01.37 1.92z"
      fill="#fff"
    />
    <circle cx="484.946" cy="308.115" r="5" fill="#fff" />
    <circle cx="577.946" cy="308.115" r="5" fill="#fff" />
    <circle cx="582.946" cy="355.115" r="5" fill="#2B8BDC" opacity=".3" />
    <circle cx="460.946" cy="355.115" r="5" fill="#2B8BDC" opacity=".3" />
  </svg>
);
