import { useEffect, useState } from 'react';
import styled from 'styled-components';

import type { ApiError } from '@pib/api';

import Alert, { AlertType } from '../Alert';

const Description = styled.div`
  word-wrap: break-word;
`;

interface Props {
  error: ApiError | null | undefined;
}

const getErrorDescription = (errors: string[] = []) => {
  if (errors.length) {
    return (
      <ul>
        {errors.map((err, idx) => (
          <Description key={idx}>{err}</Description>
        ))}
      </ul>
    );
  }
  return null;
};

const showAlert = ({
  message,
  description,
  visible,
}: {
  message: string;
  description: Nullable<JSX.Element>;
  visible: boolean;
}) => (
  <Alert message={message} type={AlertType.Error} visible={visible}>
    {description}
  </Alert>
);

const ErrorHandler = ({ error }: Props) => {
  const [visible, setIsVisible] = useState(false);

  useEffect(() => {
    if (error && Object.keys(error).length) {
      setIsVisible(true);
    }
  }, [error]);

  if (error && Object.keys(error).length) {
    const description = getErrorDescription(error.errors);
    return showAlert({ message: error.message, description, visible });
  }

  return null;
};

export default ErrorHandler;
