import { Component } from 'react';
import PropTypes from 'prop-types';

import { breakpoints } from 'modules/ui/media';
import { throttle } from 'utils';

interface Breakpoint {
  isPhone: boolean;
  isBiggerThenPhone: boolean;
  isSmallerThenTablet: boolean;
  isTablet: boolean;
  isBiggerThenTablet: boolean;
  isDesktop: boolean;
}

interface Props {
  children: (breakpoint: Breakpoint) => React.ReactNode;
}

interface State {
  breakpoint: Breakpoint;
}

class Breakpoints extends Component<Props, State> {
  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      breakpoint: this.getBreakpoint(),
    };
    this.calculateBreakpoint = throttle(
      this.calculateBreakpoint.bind(this),
      100,
    );
  }

  componentDidMount() {
    window.addEventListener('resize', this.calculateBreakpoint, false);
    this.calculateBreakpoint();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateBreakpoint, false);
  }

  getBreakpoint() {
    const width = window.innerWidth;
    return {
      isPhone: width <= breakpoints.sm,
      isBiggerThenPhone: width > breakpoints.sm,
      isSmallerThenTablet: width < breakpoints.md,
      isTablet: width > breakpoints.sm && width <= breakpoints.md,
      isBiggerThenTablet: width > breakpoints.md,
      isDesktop: width > breakpoints.md,
    };
  }

  calculateBreakpoint = () => {
    const breakpoint = this.getBreakpoint();
    this.setState({ breakpoint });
  };

  render() {
    return this.props.children({ ...this.state.breakpoint });
  }
}

export default Breakpoints;
