import styled from 'styled-components';

export const Link = styled.a<{ asButton?: boolean }>`
  display: flex;
  align-items: center;
  color: ${p => p.theme.primary};
  text-decoration: none;
  &:hover {
    opacity: 0.8;
    text-decoration: underline;
  }
`;
