import type { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AuthProvider } from '@pib/auth';
import { useConfig } from 'core/modules/config';

import history from './history';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const AppProviders = ({ children }: { children: ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient} contextSharing>
      <AuthProvider
        history={history}
        useConfig={useConfig}
        tokenType="employer"
      >
        {children}
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default AppProviders;
