import type { FC } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(fas);

interface Props extends Omit<FontAwesomeIconProps, 'icon' | 'type'> {
  type: FontAwesomeIconProps['icon'];
}

const Icon: FC<Props> = ({ type, ...rest }) => {
  return <FontAwesomeIcon icon={type} data-testid={type} {...rest} />;
};

export default Icon;
