class TemplateApi {
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getEmployersTemplates() {
        return this.fetchService.get('/employers/templates');
    }
    getEmployersCompanyTemplate({ companyId, templateId, }) {
        return this.fetchService.get(`/employers/companies/${companyId}/templates/${templateId}`);
    }
}
export default TemplateApi;
