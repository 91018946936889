import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import Icon from '../Icon';

export enum AlertType {
  SUCCESS,
  DANGER,
  WARNING,
}

interface Props {
  type: AlertType;
  canClose?: boolean;
  onClose?: () => void;
  visible: boolean;
  title?: string;
  message: ReactNode;
  className?: string;
}

const Alert: FC<Props> = ({
  type,
  canClose,
  onClose,
  visible: visibleProp,
  title,
  message,
  children,
  className,
}) => {
  const [visible, setVisible] = useState(visibleProp);

  useEffect(() => {
    setVisible(visibleProp);
  }, [visibleProp]);

  const getIconType = useCallback(() => {
    if (type === AlertType.DANGER) {
      return 'times-circle';
    } else if (type === AlertType.WARNING) {
      return 'exclamation-circle';
    }
    return 'check-circle';
  }, [type]);

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className={clsx(
            'flex items-center p-2 border mb-4',
            type === AlertType.SUCCESS &&
              'bg-success-200 border-success-300 text-neutral-700',
            type === AlertType.DANGER &&
              'bg-danger-200 border-danger-300 text-tertiary-500',
            type === AlertType.WARNING &&
              'bg-warning-200 border-warning-300 text-neutral-700',
            className,
          )}
          initial={{ y: -20, opacity: 0, z: -1 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -20, opacity: 0 }}
        >
          <Icon
            type={getIconType()}
            className={clsx(
              type === AlertType.SUCCESS && 'text-success-700',
              type === AlertType.DANGER && 'text-danger-700',
              type === AlertType.WARNING && 'text-warning-700',
            )}
          />
          <div className="flex flex-col flex-1 mx-2">
            {title && <div>{title}</div>}
            <div>{message}</div>
            {children}
          </div>
          {canClose && (
            <Icon
              className="cursor-pointer"
              type="times"
              onClick={() => {
                setVisible(false);
                onClose?.();
              }}
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Alert;
