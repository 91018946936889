import Layout from '../../Layout';
import Spinner from '../../Spinner';

const LoadingLayout = () => (
  <Layout>
    <Spinner className="mx-auto" />
  </Layout>
);

export default LoadingLayout;
