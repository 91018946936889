import type { FC, ReactNode } from 'react';
import { Component, createRef } from 'react';
import styled, { keyframes } from 'styled-components';

import withUnmountDelay from '../withUnmountDelay';

const slideInRight = keyframes`
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const slideOutRight = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 0.6;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 0.6;
  }

  to {
    opacity: 0;
  }
`;

const Wrapper = styled.div<{ variant: { visible: boolean; width: number } }>`
  animation-name: ${p => (p.variant.visible ? slideInRight : slideOutRight)};
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.white};
  width: ${p => (p.variant.width ? `${p.variant.width}px` : '500px')};
  position: absolute;
  top: 20px;
  right: 0;
  height: calc(100% - 20px);
  z-index: 500;
  box-shadow: -1px -2px 10px 2px #f1f1f1;
`;

const Overlay = styled.div<{ variant: { visible: boolean } }>`
  animation-name: ${p => (p.variant.visible ? fadeIn : fadeOut)};
  animation-duration: 0.3s;
  animation-fill-mode: both;
  background-color: ${p => p.theme.border};
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
`;

const Content = styled.div`
  padding: 20px;
  overflow: auto;
  height: 100%;
`;

export const SidePanelContent: FC = ({ children }) => (
  <Content>{children}</Content>
);

interface Props {
  onClickOutside?: () => void;
  visible: boolean;
  width: number;
  withOverlay?: boolean;
  children: ReactNode;
}

class SP extends Component<Props> {
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
    document.addEventListener('touchend', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
    document.addEventListener('touchend', this.handleClickOutside, true);
  }

  spRef = createRef<HTMLDivElement>();

  isTouch = false;

  handleClickOutside = (
    e: MouseEvent | TouchEvent | React.MouseEvent<HTMLDivElement>,
  ) => {
    const { onClickOutside } = this.props;
    if (onClickOutside) {
      if (e.type === 'touchend') {
        this.isTouch = true;
      }
      if (e.type === 'click' && this.isTouch) {
        return;
      }
      if (
        this.spRef.current &&
        !this.spRef.current.contains(e.target as Node | null)
      ) {
        onClickOutside();
      }
    }
  };

  render() {
    const { children, visible, width, withOverlay } = this.props;
    return (
      <>
        <Wrapper
          variant={{ visible, width }}
          ref={this.spRef}
          onClick={this.handleClickOutside}
        >
          {children}
        </Wrapper>
        {withOverlay && <Overlay variant={{ visible }} />}
      </>
    );
  }
}

export const SidePanel = withUnmountDelay(SP);
