import { css } from 'styled-components';

import { lens } from 'utils';

import { hex2rgb } from './helpers';

export const propNames = [
  'marginBottom',
  'marginTop',
  'marginLeft',
  'marginRight',
  'margin',
  'marginVertical',
  'marginHorizontal',
  'padding',
  'paddingTop',
  'justifyCenter',
  'justifyBetween',
  'justifyAround',
  'justifyEnd',
  'justifyStart',
  'alignCenter',
  'alignStart',
  'row',
  'column',
  'flexWrap',
  'flex',
  'center',
  'fontWeight',
  'fontSize',
  'textAlign',
];

const format = (defaultValue: number) => (p: number | string) => {
  if (typeof p === 'number') {
    return `${p}px`;
  } else if (typeof p === 'string') {
    return p;
  }
  return `${defaultValue}px}`;
};

const decamelize = (string: string) => string.split(/(?=[A-Z])/).join('-');

const defaultMargin = 10;
const formatMargin = format(defaultMargin);

export const marginProps = (p: any) => css`
  ${p.marginBottom && `margin-bottom: ${formatMargin(p.marginBottom)}`};
  ${p.marginTop && `margin-top: ${formatMargin(p.marginTop)}`};
  ${p.marginLeft && `margin-left: ${formatMargin(p.marginLeft)}`};
  ${p.marginRight && `margin-right: ${formatMargin(p.marginRight)}`};
  ${p.margin && `margin: ${formatMargin(p.margin)}`};
  ${p.marginVertical &&
  `
    margin-top: ${formatMargin(p.marginVertical)}
    margin-bottom: ${formatMargin(p.marginVertical)}
  `};
  ${p.marginHorizontal &&
  `
    margin-left: ${formatMargin(p.marginHorizontal)}
    margin-right: ${formatMargin(p.marginHorizontal)}
  `};
`;

export const paddingProps = (p: any) => css`
  ${p.paddingTop && `padding-top: ${formatMargin(p.paddingTop)}`};
  ${p.padding && `padding: ${formatMargin(p.padding)}`};
`;

export const flexProps = (p: any) => css`
  ${p.justifyCenter && 'justify-content: center'};
  ${p.justifyBetween && 'justify-content: space-between'};
  ${p.justifyAround && 'justify-content: space-around'};
  ${p.justifyEnd && 'justify-content: flex-end'};
  ${p.justifyStart && 'justify-content: flex-start'};
  ${p.alignCenter && 'align-items: center'};
  ${p.alignStart && 'align-items: flex-start;'};
  ${p.alignEnd && 'align-items: flex-end;'};
  ${p.row && 'flex-direction: row'};
  ${p.column && 'flex-direction: column'};
  ${p.flexWrap && 'flex-wrap: wrap'};
  ${p.flex && `flex: ${p.flex || 1}`};
  ${p.center &&
  css`
    justify-content: center;
    align-items: center;
  `};
`;

export const fontWeightProps = (p: any) => css`
  ${p.fontWeight && `font-weight: ${p.fontWeight}`};
`;
export const fontSizeProps = (p: any) => css`
  ${p.fontSize && `font-size: ${p.fontSize}rem`};
`;
export const textAlignProps = (p: any) => css`
  ${p.textAlign && `text-align: ${p.textAlign}`};
`;

export const colorProps = (type: string) => (p: any) => {
  if (p[type] && lens(p.theme, p[type])) {
    return css`
      ${decamelize(type)}: ${lens(p.theme, p[type])}
    `;
  } else if (p[type]) {
    return `${decamelize(type)}: ${p[type]}`;
  }
  return null;
};

export const withBoxShadow = ({
  x = 0,
  y = 0,
  blur = 0,
  spread = 0,
  color = '#000',
  opacity = 1,
} = {}) =>
  css`
    box-shadow: ${`${x}px ${y}px ${blur}px ${spread}px ${hex2rgb(
      color,
      opacity,
    )}`};
  `;

export interface MarginProps {
  marginBottom?: number;
  marginTop?: number | string;
  marginLeft?: number;
  marginRight?: number | boolean;
  margin?: number | string;
  marginVertical?: number;
  marginHorizontal?: number;
}

export interface PaddingProps {
  paddingTop?: number;
  padding?: number | string;
}

export interface FlexProps {
  justifyCenter?: boolean;
  justifyBetween?: boolean;
  justifyAround?: boolean;
  justifyEnd?: boolean;
  justifyStart?: boolean;
  alignCenter?: boolean;
  alignStart?: boolean;
  alignEnd?: boolean;
  row?: boolean;
  column?: boolean;
  flexWrap?: boolean;
  flex?: number;
  center?: boolean;
}

export interface FontProps {
  fontSize?: number;
  fontWeight?: string | number;
  textAlign?: string;
}

export type StyleProps = MarginProps & FlexProps & PaddingProps & FontProps;
