export const hex2rgb = (hex, opacity) => {
  let h = hex.replace('#', '');
  h = h.match(new RegExp(`(.{${h.length / 3}})`, 'g'));
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < h.length; i++) {
    h[i] = parseInt(h[i].length === 1 ? h[i] + h[i] : h[i], 16);
  }
  h.push(opacity);
  return `rgba(${h.join(',')})`;
};

export const lens = (obj, path) =>
  path.split('.').reduce((o, key) => (o && o[key] ? o[key] : null), obj);
