import type { FC, ReactNode } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';

import { paddingProps } from 'modules/ui';

const Wrapper = styled.div<{ variant: { fullWidth?: boolean } }>`
  ${paddingProps};
  border-radius: 5px;
  border: 1px solid ${p => p.theme.white};
  background-color: ${p => p.theme.white};
  ${p => p.variant.fullWidth && 'grid-column: 1 / -1;'};
`;

const CardSeparator: FC = () => <div className="h-px bg-neutral-200" />;

interface CardHeaderProps {
  title: ReactNode;
  className?: string;
  onClick?: () => void;
}

const CardHeader: FC<CardHeaderProps> = ({ title, children, className }) => (
  <div className={clsx('flex justify-between items-center mb-2', className)}>
    <div className="uppercase font-semibold p-3">{title}</div>
    {children}
  </div>
);

interface CardImageProps {
  src: string;
  alt: string;
}

const CardImage: React.FC<CardImageProps> = ({ src, alt }) => (
  <img className="w-full rounded-t-sm" src={src} alt={alt} />
);

interface CardProps {
  className?: string;
  fullWidth?: boolean;
  onClick?: () => void;
}

interface CardComposition {
  Header: typeof CardHeader;
  Image: typeof CardImage;
  Separtor: typeof CardSeparator;
}

const Card: React.FC<CardProps> & CardComposition = ({
  children,
  className,
  fullWidth,
  onClick,
}) => (
  <Wrapper className={className} variant={{ fullWidth }} onClick={onClick}>
    {children}
  </Wrapper>
);

Card.Header = CardHeader;
Card.Image = CardImage;
Card.Separtor = CardSeparator;

export default Card;
