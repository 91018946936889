import type { ComponentType } from 'react';
import { Component } from 'react';

interface Props {
  visible: boolean;
  delayTime?: number;
}

const withUnmountDelay = <P extends Record<string, any>>(
  WrappedComponent: ComponentType<P>,
) =>
  class extends Component<P & Props> {
    static defaultProps = {
      delayTime: 300,
    };

    state = {
      shouldRender: this.props.visible,
    };

    componentDidUpdate(prevProps: Props) {
      if (!this.props.visible && prevProps.visible) {
        setTimeout(
          () => this.setState({ shouldRender: false }),
          this.props.delayTime,
        );
      } else if (this.props.visible && !prevProps.visible) {
        this.setState({ shouldRender: true });
      }
    }

    triggerInvisible = () => {
      this.setState({ shouldRender: false });
    };

    render() {
      const { shouldRender } = this.state;
      return shouldRender ? (
        <WrappedComponent
          triggerInvisible={this.triggerInvisible}
          {...this.props}
        />
      ) : null;
    }
  };

export default withUnmountDelay;
