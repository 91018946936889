import type { ButtonHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import { hex2rgb } from './helpers';
import { colorProps, fontWeightProps, marginProps } from './props';

interface Variant {
  fullWidth?: boolean;
  size?: 'sm' | 'lg';
}

export const StyledButton = styled.button<Variant>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  ${p => p.fullWidth && 'width: 100%;'};
  ${p => {
    if (p.size === 'sm') {
      return 'height: 26px';
    } else if (p.size === 'lg') {
      return 'height: 46px';
    }
    return 'height: 32px';
  }};
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-color: ${p => p.theme.border};
  outline: 0;
  color: #fff;
  background-color: ${p => p.theme.primary};
  ${colorProps('color')};
  ${colorProps('backgroundColor')};
  ${colorProps('borderColor')};
  ${fontWeightProps};
  ${marginProps} &:hover {
    background-color: ${p =>
      hex2rgb(colorProps('backgroundColor')(p) || p.theme.primary, 0.8)};
  }
  &:active {
    background-color: ${p =>
      hex2rgb(colorProps('backgroundColor')(p) || p.theme.primary, 0.8)};
  }
  &:disabled {
    background-color: ${p =>
      hex2rgb(colorProps('backgroundColor')(p) || p.theme.text, 0.8)};
    cursor: not-allowed;
  }
`;

interface Props {
  className?: string;
  variant?: Variant;
  children: ReactNode;
}

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & Props;

const Button = ({
  className,
  variant = {},
  children,
  ...rest
}: ButtonProps) => (
  <StyledButton type="button" className={className} {...variant} {...rest}>
    {children}
  </StyledButton>
);

export { Button };
