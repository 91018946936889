import type { ReactNode } from 'react';

import { Icon } from '../../modules/ui';
import * as S from './styles';

interface Props {
  href: string;
  children: ReactNode;
  showIcon?: boolean;
  target?: string;
  rel?: string;
}

const ExternalLink = ({
  href,
  children,
  showIcon = true,
  target = '_blank',
  rel = 'noopener noreferrer',
  ...rest
}: Props) => (
  <S.Link href={href} target={target} rel={rel} {...rest}>
    {children}
    {showIcon && (
      <Icon color="border" type="external-link-alt" size="xs" marginLeft={4} />
    )}
  </S.Link>
);

export default ExternalLink;
