import { useQuery } from 'react-query';

import type { ApiError } from '@pib/api';
import type { ApiDashboard } from '@pib/api/dist/dashboard/types';

import { dashboardApi } from '../../di';
import EmployerDashboardQueryKeys from './EmployerDashboardQueryKeys';

const employerDashboardQueryKeys = new EmployerDashboardQueryKeys();

export const useDashboard = () => {
  const { data, isLoading, error } = useQuery<ApiDashboard, ApiError>(
    employerDashboardQueryKeys.getDashboard(),
    () => dashboardApi.getEmployerDashboardData(),
  );

  return {
    dashboard: data ?? null,
    isLoading,
    error,
  };
};
