import type { FC } from 'react';
import styled from 'styled-components';

import Sidebar from '../Sidebar';

const LayoutWrapper = styled.div`
  min-height: calc(100vh - 8rem);
`;

const Layout: FC = ({ children }) => {
  return (
    <LayoutWrapper className="flex flex-1 text-neutral-100">
      <Sidebar />
      <main className="flex-1 bg-neutral-100 p-2 md:p-4 overflow-hidden flex">
        {children}
      </main>
    </LayoutWrapper>
  );
};

export default Layout;
