export default ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 531.26 270.86"
    className={className}
  >
    <title>myben</title>
    <path
      fill="#ffffff"
      d="M193.64,101.34c0-15.2-8.14-19.65-20-19.65H154v38.08h20.42C187.34,119.77,193.64,112.09,193.64,101.34Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M118.36,120.31l14.51,18.88V96.87l-13.4,10.3C115.59,110.15,115.09,116.06,118.36,120.31Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M28.86,237.66H41.94a25.35,25.35,0,0,1,6.65.6,12.15,12.15,0,0,1,9.13,12.32c0,5.16-2.42,10.72-9.35,12.25a27.87,27.87,0,0,1-6.54.5H36.17v13.14H28.86Zm7.31,6.21v13.47h5.22a16.36,16.36,0,0,0,4.13-.33c4.5-1.1,4.78-5.22,4.78-6.6a6.42,6.42,0,0,0-1.93-4.95c-1.81-1.53-4.34-1.59-6.7-1.59Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M77,268.88H62.41l-2.26,7.59H52.4l13.08-38.81h8.25L87,276.47H79.23Zm-1.92-6.32-5.33-17.87-5.39,17.87Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M88.68,237.66h12.37c5.17.05,8.63.05,12,2.8a11.82,11.82,0,0,1,4.29,9.57,10.62,10.62,0,0,1-7.64,10.66l8.63,15.78h-8.3l-7.7-14.41H95.89v14.41H88.68Zm7.21,6.27v12.14h2.52c2.48,0,3.41-.05,4.79-.11,1.59-.05,6.81-.33,6.81-6a5.55,5.55,0,0,0-3.08-5.22c-1.53-.77-3.4-.82-7.69-.82Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M146.19,244.25H135.47v32.22h-7.26V244.25h-10.5v-6.59h28.48Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M171.36,237.66h7.15v38.81h-6.32l-17-27.21.44,27.21h-7.09V237.66h6.54l17.15,27.43Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M193.41,244.14v9.13H212.1v6.27H193.41v10.39h21.82v6.54h-29V237.66h27.6v6.48Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M219.8,237.66h12.37c5.16.05,8.63.05,12,2.8a11.82,11.82,0,0,1,4.29,9.57,10.62,10.62,0,0,1-7.64,10.66l8.63,15.78h-8.3l-7.7-14.41H227v14.41h-7.2Zm7.2,6.27v12.14h2.53c2.47,0,3.41-.05,4.78-.11,1.59-.05,6.82-.33,6.82-6a5.57,5.57,0,0,0-3.08-5.22c-1.54-.77-3.41-.82-7.7-.82Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M261.41,237.66v38.81h-7.14V237.66Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M292.25,237.66h7.15v38.81h-6.32l-17-27.21.44,27.21h-7.09V237.66H276l17.15,27.43Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M307.32,237.66h13.85c5.17.05,6.54.11,8.41.66a9.18,9.18,0,0,1,5.17,14.12,7.29,7.29,0,0,1-4.84,2.81,9.93,9.93,0,0,1,8.3,10.22,10.15,10.15,0,0,1-6.93,10.06c-2.47.88-4.39.94-8.79.94H307.32Zm7.2,5.93v9.68H317c2,0,3.52,0,4.4-.06a10.75,10.75,0,0,0,4.95-.77,4.41,4.41,0,0,0,.33-7.86c-1.54-.87-2.31-.93-7.59-1Zm0,15.12V270.2h7.14c4.29,0,5.45,0,7.31-1.32a5,5,0,0,0,1.76-4.07,6,6,0,0,0-.71-2.91c-1.76-3.13-5.83-3.13-8.3-3.13Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M351.13,244.14v9.13h18.69v6.27H351.13v10.39H373v6.54H344V237.66h27.6v6.48Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M400.38,237.66h7.15v38.81h-6.32l-17-27.21.44,27.21h-7.09V237.66h6.54l17.16,27.43Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M422.43,244.14v9.13h18.69v6.27H422.43v10.39h21.82v6.54h-29V237.66h27.6v6.48Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M456,253.82h16.22V260H456v16.44h-7.2V237.66h25.67v6.48H456Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M484.66,237.66v38.81h-7.14V237.66Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M515.61,244.25H504.89v32.22h-7.25V244.25h-10.5v-6.59h28.47Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M520.84,264.92c.93,1.43,3.84,5.89,12,5.89a11.06,11.06,0,0,0,4.18-.66c2.81-1.16,3.3-3.14,3.3-4.46a4,4,0,0,0-2.91-3.85,30.28,30.28,0,0,0-4.51-1.2l-4.67-1a34.41,34.41,0,0,1-4.89-1.38,9.9,9.9,0,0,1-6.55-9.51c0-7.36,6.21-12.09,15.34-12.09a18.15,18.15,0,0,1,15.61,7.86L541.83,249c-1.31-1.92-3.84-5.77-10.05-5.77-3.91,0-7.32,1.76-7.32,4.73,0,3.3,3.63,4,7.15,4.62l4,.87c5.23,1.1,12.59,3.25,12.59,11.55,0,8.9-8.24,12.48-16.49,12.48a26.08,26.08,0,0,1-6.93-.88,17.48,17.48,0,0,1-10.06-7.37Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M560.12,234.81a3.39,3.39,0,0,1-3.46,3.42,3.4,3.4,0,1,1,0-6.8A3.38,3.38,0,0,1,560.12,234.81Zm-6.07,0a2.62,2.62,0,0,0,2.63,2.7,2.59,2.59,0,0,0,2.58-2.68,2.61,2.61,0,1,0-5.21,0Zm2.08,1.77h-.79V233.2a7.13,7.13,0,0,1,1.3-.1,2.08,2.08,0,0,1,1.18.25.94.94,0,0,1,.33.74.86.86,0,0,1-.7.78v0a1,1,0,0,1,.61.83,2.79,2.79,0,0,0,.25.84h-.84a2.54,2.54,0,0,1-.27-.82c-.06-.37-.27-.54-.7-.54h-.37Zm0-1.92h.37c.43,0,.78-.14.78-.49s-.22-.51-.72-.51a1.86,1.86,0,0,0-.43,0Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M255.67,122.21c-4-2.28-6-2.42-19.61-2.56h-11.8v25h6.4c5.26,0,9.09,0,11.37-.15,4.69,0,9.38-.14,12.79-2a11.4,11.4,0,0,0,.85-20.32Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M242.88,158.88l-18.62-.14v29.71h18l23.39-18a14.2,14.2,0,0,0-1.35-3.49C259.8,158.88,249.28,158.88,242.88,158.88Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M205.64,204.65v-71c9.75-6.84,14.36-17.8,14.48-29.39h21.34c13.36.14,16.91.28,21.75,1.7a25.66,25.66,0,0,1,6.94,3.26l-51-66.45c-3.27-4.24-9.11-5.28-13-2.3L175,64.49h1.34c21.9,0,34.25,11.45,37.4,28a49.17,49.17,0,0,1,.64,8.08c-.09,22.08-15,36-37,36.41H153.44v29l37.95,49.36c3.26,4.24,9.11,5.28,13,2.3l16.86-13Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M292.19,137.81l-12.56-16.27a23.16,23.16,0,0,1,1.2,7.35,24.14,24.14,0,0,1-4.26,13.64c-3.13,4.13-7.11,6.26-12.51,7.25a26.09,26.09,0,0,1,16.06,9.59l11-8.42C295,148,295.46,142.06,292.19,137.81Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M37.48,304.67a7.24,7.24,0,0,1-.26,2.09,3.31,3.31,0,0,1-.89,1.49,3.83,3.83,0,0,1-1.66.89,9.32,9.32,0,0,1-2.55.29h-3V292.6h3a8.55,8.55,0,0,1,2.31.26,3.74,3.74,0,0,1,1.5.78,3,3,0,0,1,.82,1.31,6.11,6.11,0,0,1,.25,1.86,4.68,4.68,0,0,1-.25,1.64,3.33,3.33,0,0,1-.65,1.13,2.81,2.81,0,0,1-.93.71,4.48,4.48,0,0,1-1.05.39,5.59,5.59,0,0,1,1.33.42,3.22,3.22,0,0,1,1.06.76,3.54,3.54,0,0,1,.71,1.16A4.54,4.54,0,0,1,37.48,304.67Zm-2.43-7.79a5.41,5.41,0,0,0-.14-1.39,1.61,1.61,0,0,0-.5-.84,2.24,2.24,0,0,0-1-.42,8.92,8.92,0,0,0-1.6-.12H31v5.94h.65a3.68,3.68,0,0,0,2.68-.78A3.34,3.34,0,0,0,35.05,296.88Zm.53,7.79a4,4,0,0,0-.25-1.53,2.22,2.22,0,0,0-.75-1,3.41,3.41,0,0,0-1.24-.52,8.81,8.81,0,0,0-1.72-.15H31v6.42H32a7.37,7.37,0,0,0,1.7-.17,2.49,2.49,0,0,0,1.09-.54,2,2,0,0,0,.58-1A5.7,5.7,0,0,0,35.58,304.67Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M42.53,309.43V292.6h6.71v1.75H44.43v5.22h4.33v1.64H44.43v6.47h4.81v1.75Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M59.15,309.43a7.23,7.23,0,0,1-.79.17,5.1,5.1,0,0,1-.87.07,4.62,4.62,0,0,1-1.64-.3,3.07,3.07,0,0,1-1.44-1.23,7.74,7.74,0,0,1-1-2.63A21,21,0,0,1,53,301a17.52,17.52,0,0,1,.47-4.49,8,8,0,0,1,1.19-2.64,3.53,3.53,0,0,1,1.57-1.22,4.82,4.82,0,0,1,1.58-.3,5,5,0,0,1,.92.07,3.47,3.47,0,0,1,.66.17,2.2,2.2,0,0,1,.56.24v1.66a2.72,2.72,0,0,0-.6-.26,6.23,6.23,0,0,0-.71-.17,5.11,5.11,0,0,0-.9-.08,1.92,1.92,0,0,0-1,.33,2.65,2.65,0,0,0-.91,1.12,8.23,8.23,0,0,0-.65,2.15A19.11,19.11,0,0,0,54.9,301a23.22,23.22,0,0,0,.19,3.27,9,9,0,0,0,.53,2.15,2.68,2.68,0,0,0,.82,1.18,1.66,1.66,0,0,0,1.05.37,5,5,0,0,0,.85-.08,5.46,5.46,0,0,0,.79-.17,4.36,4.36,0,0,0,.82-.26v1.73A5.29,5.29,0,0,1,59.15,309.43Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M71.08,309.43l-.84-4H65.58l-.85,4H62.62l4.16-16.83H69l4.16,16.83Zm-2.43-11.74c-.08-.41-.16-.85-.25-1.31s-.17-.88-.25-1.28-.16-.92-.24-1.37c-.08.45-.16.9-.24,1.37s-.17.83-.25,1.28l-.26,1.31-1.27,6.11h4Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M84.42,308.63a4.26,4.26,0,0,1-3.09,1,6.22,6.22,0,0,1-1.82-.24,3,3,0,0,1-1.3-.78,3.55,3.55,0,0,1-.78-1.41,7.63,7.63,0,0,1-.26-2.14V292.6h1.9v12.86a3,3,0,0,0,.55,2,2.2,2.2,0,0,0,1.71.61,2.23,2.23,0,0,0,1.65-.6,2.79,2.79,0,0,0,.61-2.05V292.6h1.9v12.5A4.84,4.84,0,0,1,84.42,308.63Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M97,305.12a5.59,5.59,0,0,1-.43,2.41,3.74,3.74,0,0,1-1.1,1.37,3.55,3.55,0,0,1-1.45.63,9,9,0,0,1-1.51.14,6.44,6.44,0,0,1-.89-.06,6.2,6.2,0,0,1-.77-.15c-.28-.07-.52-.14-.75-.22v-1.78a5.29,5.29,0,0,0,.8.24c.22.06.48.12.77.17a4.76,4.76,0,0,0,.84.07,4.09,4.09,0,0,0,1-.12,2.07,2.07,0,0,0,.82-.42,2,2,0,0,0,.57-.83,3.7,3.7,0,0,0,.2-1.35,4,4,0,0,0-.14-1.16,3.55,3.55,0,0,0-.39-.88,3.78,3.78,0,0,0-.55-.7l-.68-.65-1.4-1.3A5.79,5.79,0,0,1,90.8,299a4.63,4.63,0,0,1-.5-2.28,5.23,5.23,0,0,1,.37-2.12,3.58,3.58,0,0,1,1-1.33,3.66,3.66,0,0,1,1.32-.7,5.07,5.07,0,0,1,1.42-.2,5.19,5.19,0,0,1,.8.06l.72.13.72.19v1.71a6.1,6.1,0,0,0-.72-.23l-.72-.15a4.07,4.07,0,0,0-.79-.08,2.19,2.19,0,0,0-1.58.6,2.58,2.58,0,0,0-.62,1.91,3.23,3.23,0,0,0,.41,1.71,5.91,5.91,0,0,0,1,1.2l1.29,1.23a10.2,10.2,0,0,1,.79.82,5.58,5.58,0,0,1,.69,1,4.84,4.84,0,0,1,.47,1.19A5.84,5.84,0,0,1,97,305.12Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M101.72,309.43V292.6h6.71v1.75h-4.81v5.22H108v1.64h-4.33v6.47h4.81v1.75Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M127.24,309.43v-8.22h-5.1v8.22h-1.9V292.6h1.9v7h5.1v-7h1.9v16.83Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M141.7,309.43l-.84-4h-4.67l-.84,4h-2.12l4.16-16.83h2.26l4.16,16.83Zm-2.43-11.74c-.08-.41-.17-.85-.25-1.31l-.26-1.28c-.08-.47-.16-.92-.24-1.37-.08.45-.16.9-.24,1.37s-.16.83-.25,1.28-.17.9-.25,1.31l-1.28,6.11h4Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M155.72,298.37a7.13,7.13,0,0,1-.4,2.56,4.1,4.1,0,0,1-1.16,1.71,4.8,4.8,0,0,1-1.88,1,11,11,0,0,1-2.6.36v5.46h-1.9V292.6h2.58a8,8,0,0,1,2.5.34,3.87,3.87,0,0,1,1.66,1.06,4.46,4.46,0,0,1,.91,1.79A10.25,10.25,0,0,1,155.72,298.37Zm-1.9,0a9.76,9.76,0,0,0-.19-2.08A2.86,2.86,0,0,0,153,295a2.15,2.15,0,0,0-1.05-.62,6.1,6.1,0,0,0-1.51-.17h-.76v8.32a7.19,7.19,0,0,0,2-.31,3,3,0,0,0,1.27-.78,3.11,3.11,0,0,0,.67-1.29A7.89,7.89,0,0,0,153.82,298.37Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M168.23,298.37a7.36,7.36,0,0,1-.4,2.56,4.19,4.19,0,0,1-1.16,1.71,4.87,4.87,0,0,1-1.88,1,11,11,0,0,1-2.6.36v5.46h-1.9V292.6h2.57a8,8,0,0,1,2.5.34A3.81,3.81,0,0,1,167,294a4.35,4.35,0,0,1,.92,1.79A10.25,10.25,0,0,1,168.23,298.37Zm-1.9,0a9.08,9.08,0,0,0-.2-2.08,2.85,2.85,0,0,0-.62-1.27,2.19,2.19,0,0,0-1.06-.62,6,6,0,0,0-1.51-.17h-.75v8.32a7.19,7.19,0,0,0,2-.31,3,3,0,0,0,1.26-.78,2.9,2.9,0,0,0,.67-1.29A7.33,7.33,0,0,0,166.33,298.37Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M176.26,302.51v6.92h-1.9v-6.92l-4.09-9.91h2.36l2.68,8.22L178,292.6h2.36Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M191,309.43V292.6h6.71v1.75h-4.81v5.22h4.33v1.64h-4.33v6.47h4.81v1.75Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M212.75,309.43l-.17-8.8c0-.47,0-.95,0-1.47s0-1,0-1.42,0-1,0-1.49c-.16.43-.31.86-.46,1.28s-.26.72-.39,1.1a10.59,10.59,0,0,1-.4,1l-3,6.76-3-6.76c-.13-.27-.26-.6-.4-1s-.27-.74-.39-1.09-.29-.84-.44-1.27q0,.77,0,1.53v2.82l-.17,8.8h-1.9l.58-16.83h1.53l4.14,10.22,4.16-10.22h1.52l.58,16.83Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M228.23,298.37a7.37,7.37,0,0,1-.39,2.56,4.29,4.29,0,0,1-1.16,1.71,4.84,4.84,0,0,1-1.89,1,10.86,10.86,0,0,1-2.59.36v5.46h-1.9V292.6h2.57a8,8,0,0,1,2.5.34A3.87,3.87,0,0,1,227,294a4.47,4.47,0,0,1,.92,1.79A10.29,10.29,0,0,1,228.23,298.37Zm-1.9,0a9.15,9.15,0,0,0-.19-2.08,2.85,2.85,0,0,0-.62-1.27,2.23,2.23,0,0,0-1.06-.62,6,6,0,0,0-1.51-.17h-.75v8.32a7.06,7.06,0,0,0,2-.31,3,3,0,0,0,1.27-.78,2.9,2.9,0,0,0,.67-1.29A7.36,7.36,0,0,0,226.33,298.37Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M238.31,309.43H232.8V292.6h1.9v15h3.85Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M250.6,301a19.19,19.19,0,0,1-.43,4.57,7.66,7.66,0,0,1-1.11,2.63,3.25,3.25,0,0,1-1.5,1.19,5.28,5.28,0,0,1-1.65.27,5.37,5.37,0,0,1-1.65-.27,3.22,3.22,0,0,1-1.5-1.18,7.45,7.45,0,0,1-1.11-2.61,20,20,0,0,1-.43-4.6,18.65,18.65,0,0,1,.45-4.57,7.48,7.48,0,0,1,1.13-2.62,3.2,3.2,0,0,1,1.51-1.19,4.88,4.88,0,0,1,3.2,0,3.27,3.27,0,0,1,1.52,1.19,7.87,7.87,0,0,1,1.13,2.62A19.23,19.23,0,0,1,250.6,301Zm-1.9,0a22.21,22.21,0,0,0-.22-3.44,8.56,8.56,0,0,0-.59-2.22,2.88,2.88,0,0,0-.89-1.19,1.84,1.84,0,0,0-2.17,0,2.86,2.86,0,0,0-.88,1.18,8.51,8.51,0,0,0-.61,2.21,22.27,22.27,0,0,0-.22,3.47,21.5,21.5,0,0,0,.24,3.54,8.52,8.52,0,0,0,.65,2.21,2.65,2.65,0,0,0,.89,1.14,1.83,1.83,0,0,0,1,.33,1.81,1.81,0,0,0,1-.33,2.74,2.74,0,0,0,.9-1.14,8.56,8.56,0,0,0,.64-2.21A21.5,21.5,0,0,0,248.7,301Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M259,302.51v6.92h-1.9v-6.92L253,292.6h2.35l2.69,8.22,2.68-8.22h2.35Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M266.72,309.43V292.6h6.71v1.75h-4.81v5.22h4.32v1.64h-4.32v6.47h4.81v1.75Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M278,309.43V292.6h6.71v1.75h-4.81v5.22h4.33v1.64h-4.33v6.47h4.81v1.75Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M295.22,305.12a5.58,5.58,0,0,1-.44,2.41,3.74,3.74,0,0,1-1.1,1.37,3.49,3.49,0,0,1-1.45.63,8.93,8.93,0,0,1-1.51.14,6.44,6.44,0,0,1-.89-.06,6.2,6.2,0,0,1-.77-.15c-.27-.07-.52-.14-.75-.22v-1.78a5.29,5.29,0,0,0,.8.24c.22.06.48.12.77.17a4.92,4.92,0,0,0,.84.07,4.09,4.09,0,0,0,1-.12,2.11,2.11,0,0,0,.83-.42,1.94,1.94,0,0,0,.56-.83,3.68,3.68,0,0,0,.21-1.35,4,4,0,0,0-.15-1.16,3.22,3.22,0,0,0-.39-.88,3.78,3.78,0,0,0-.55-.7l-.68-.65-1.4-1.3A5.79,5.79,0,0,1,289,299a4.75,4.75,0,0,1-.5-2.28,5.22,5.22,0,0,1,.38-2.12,3.46,3.46,0,0,1,1-1.33,3.66,3.66,0,0,1,1.32-.7,5.12,5.12,0,0,1,1.42-.2,5.19,5.19,0,0,1,.8.06c.26,0,.5.08.72.13l.72.19v1.71a6.1,6.1,0,0,0-.72-.23l-.71-.15a4.15,4.15,0,0,0-.79-.08,2.21,2.21,0,0,0-1.59.6,2.58,2.58,0,0,0-.62,1.91,3.23,3.23,0,0,0,.41,1.71,6.25,6.25,0,0,0,1,1.2l1.3,1.23c.27.26.53.53.79.82a6.12,6.12,0,0,1,.68,1,5.29,5.29,0,0,1,.48,1.19A5.84,5.84,0,0,1,295.22,305.12Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M317.61,309.43l-.17-8.8c0-.47,0-.95,0-1.47s0-1,0-1.42,0-1,0-1.49c-.16.43-.31.86-.45,1.28l-.4,1.1a10.59,10.59,0,0,1-.4,1l-3,6.76-3-6.76a9.08,9.08,0,0,1-.39-1c-.14-.37-.27-.74-.4-1.09s-.29-.84-.43-1.27c0,.51,0,1,0,1.53v2.82l-.17,8.8H307l.58-16.83h1.54l4.13,10.22,4.17-10.22h1.51l.58,16.83Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M331.82,309.43l-.84-4h-4.67l-.84,4h-2.11l4.16-16.83h2.26l4.16,16.83Zm-2.43-11.74c-.08-.41-.16-.85-.25-1.31s-.17-.88-.25-1.28-.16-.92-.24-1.37c-.08.45-.16.9-.24,1.37l-.26,1.28c-.08.46-.17.9-.25,1.31l-1.27,6.11h4Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M337.91,309.43V292.6h1.9v16.83Zm6.61.24-4.21-9.4,3.9-7.67h2.36l-4.33,7.67,4.57,9.16Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M350.78,309.43V292.6h6.71v1.75h-4.81v5.22H357v1.64h-4.32v6.47h4.81v1.75Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M376.29,309.43v-8.22h-5.1v8.22h-1.9V292.6h1.9v7h5.1v-7h1.9v16.83Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M390.75,309.43l-.84-4h-4.67l-.84,4h-2.12l4.16-16.83h2.26l4.17,16.83Zm-2.43-11.74c-.08-.41-.17-.85-.25-1.31l-.26-1.28c-.08-.47-.16-.92-.24-1.37-.08.45-.16.9-.24,1.37s-.16.83-.25,1.28-.17.9-.25,1.31l-1.28,6.11h4Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M404.77,298.37a7.13,7.13,0,0,1-.4,2.56,4.1,4.1,0,0,1-1.16,1.71,4.73,4.73,0,0,1-1.88,1,10.87,10.87,0,0,1-2.6.36v5.46h-1.9V292.6h2.58a8,8,0,0,1,2.5.34,3.87,3.87,0,0,1,1.66,1.06,4.46,4.46,0,0,1,.91,1.79A10.25,10.25,0,0,1,404.77,298.37Zm-1.9,0a9.15,9.15,0,0,0-.19-2.08,2.85,2.85,0,0,0-.62-1.27,2.23,2.23,0,0,0-1.06-.62,6.1,6.1,0,0,0-1.51-.17h-.76v8.32a7.19,7.19,0,0,0,2-.31,3,3,0,0,0,1.27-.78,3,3,0,0,0,.67-1.29A7.36,7.36,0,0,0,402.87,298.37Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M417.28,298.37a7.36,7.36,0,0,1-.4,2.56,4.19,4.19,0,0,1-1.16,1.71,4.87,4.87,0,0,1-1.88,1,11,11,0,0,1-2.6.36v5.46h-1.9V292.6h2.57a8,8,0,0,1,2.51.34,3.93,3.93,0,0,1,1.66,1.06,4.46,4.46,0,0,1,.91,1.79A10.25,10.25,0,0,1,417.28,298.37Zm-1.9,0a9.08,9.08,0,0,0-.2-2.08,2.75,2.75,0,0,0-.62-1.27,2.14,2.14,0,0,0-1.06-.62,6,6,0,0,0-1.5-.17h-.76v8.32a7.19,7.19,0,0,0,2-.31,3,3,0,0,0,1.26-.78,2.9,2.9,0,0,0,.67-1.29A7.33,7.33,0,0,0,415.38,298.37Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M425.31,302.51v6.92h-1.9v-6.92l-4.09-9.91h2.36l2.68,8.22L427,292.6h2.36Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M445.44,309.43a7.23,7.23,0,0,1-.79.17,5.1,5.1,0,0,1-.87.07,4.72,4.72,0,0,1-1.65-.3,3.1,3.1,0,0,1-1.43-1.23,7.74,7.74,0,0,1-1-2.63,20.24,20.24,0,0,1-.4-4.5,17,17,0,0,1,.48-4.49,7.76,7.76,0,0,1,1.19-2.64,3.53,3.53,0,0,1,1.57-1.22,4.76,4.76,0,0,1,1.57-.3,5,5,0,0,1,.93.07,3.68,3.68,0,0,1,.66.17,2.13,2.13,0,0,1,.55.24v1.66a2.36,2.36,0,0,0-.6-.26,5.75,5.75,0,0,0-.71-.17,5.11,5.11,0,0,0-.9-.08,1.89,1.89,0,0,0-1,.33,2.58,2.58,0,0,0-.91,1.12,7.87,7.87,0,0,0-.65,2.15,19,19,0,0,0-.26,3.41,23,23,0,0,0,.2,3.27,8.52,8.52,0,0,0,.53,2.15,2.67,2.67,0,0,0,.81,1.18,1.69,1.69,0,0,0,1.06.37,5,5,0,0,0,.84-.08,5.61,5.61,0,0,0,.8-.17,4,4,0,0,0,.81-.26v1.73A5.16,5.16,0,0,1,445.44,309.43Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M457.72,308.63a4.26,4.26,0,0,1-3.09,1,6.22,6.22,0,0,1-1.82-.24,3,3,0,0,1-1.3-.78,3.55,3.55,0,0,1-.78-1.41,7.63,7.63,0,0,1-.26-2.14V292.6h1.9v12.86a3.08,3.08,0,0,0,.54,2,2.65,2.65,0,0,0,3.37,0,2.79,2.79,0,0,0,.61-2.05V292.6h1.9v12.5A4.84,4.84,0,0,1,457.72,308.63Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M470.33,305.12a5.43,5.43,0,0,1-.44,2.41,3.62,3.62,0,0,1-1.09,1.37,3.55,3.55,0,0,1-1.45.63,9,9,0,0,1-1.51.14,6.33,6.33,0,0,1-.89-.06,6.2,6.2,0,0,1-.77-.15c-.28-.07-.52-.14-.75-.22v-1.78a5.29,5.29,0,0,0,.8.24c.22.06.48.12.76.17a5,5,0,0,0,.85.07,4,4,0,0,0,1-.12,2,2,0,0,0,1.4-1.25,3.7,3.7,0,0,0,.2-1.35,4,4,0,0,0-.14-1.16,3.55,3.55,0,0,0-.39-.88,3.78,3.78,0,0,0-.55-.7l-.68-.65-1.4-1.3a6,6,0,0,1-1.18-1.54,4.75,4.75,0,0,1-.49-2.28,5.23,5.23,0,0,1,.37-2.12,3.58,3.58,0,0,1,1-1.33,3.66,3.66,0,0,1,1.32-.7,5.07,5.07,0,0,1,1.42-.2,5.29,5.29,0,0,1,.8.06l.72.13.72.19v1.71a6.1,6.1,0,0,0-.72-.23l-.72-.15a4.07,4.07,0,0,0-.79-.08,2.19,2.19,0,0,0-1.58.6,2.58,2.58,0,0,0-.62,1.91,3.23,3.23,0,0,0,.41,1.71,5.91,5.91,0,0,0,1,1.2l1.29,1.23a10.2,10.2,0,0,1,.79.82,5.58,5.58,0,0,1,.69,1,5.45,5.45,0,0,1,.65,2.7Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M481.73,294.4h-3.24v15h-1.9v-15H473.1v-1.8H482Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M493.54,301a19.19,19.19,0,0,1-.43,4.57,7.66,7.66,0,0,1-1.11,2.63,3.25,3.25,0,0,1-1.5,1.19,5.15,5.15,0,0,1-3.29,0,3.19,3.19,0,0,1-1.51-1.18,7.27,7.27,0,0,1-1.1-2.61,19.33,19.33,0,0,1-.44-4.6,18.65,18.65,0,0,1,.45-4.57,7.48,7.48,0,0,1,1.13-2.62,3.2,3.2,0,0,1,1.51-1.19,5.21,5.21,0,0,1,1.6-.27,5.12,5.12,0,0,1,1.6.27,3.22,3.22,0,0,1,1.52,1.19,7.87,7.87,0,0,1,1.13,2.62A19.23,19.23,0,0,1,493.54,301Zm-1.9,0a22.21,22.21,0,0,0-.22-3.44,8.56,8.56,0,0,0-.59-2.22,2.88,2.88,0,0,0-.89-1.19,1.84,1.84,0,0,0-2.17,0,2.86,2.86,0,0,0-.88,1.18,8.51,8.51,0,0,0-.61,2.21,23.58,23.58,0,0,0-.22,3.47,20.32,20.32,0,0,0,.25,3.54,8.18,8.18,0,0,0,.64,2.21,2.66,2.66,0,0,0,.9,1.14,1.78,1.78,0,0,0,1,.33,1.81,1.81,0,0,0,1-.33,2.74,2.74,0,0,0,.9-1.14,8.56,8.56,0,0,0,.64-2.21A21.5,21.5,0,0,0,491.64,301Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M508.84,309.43l-.17-8.8c0-.47,0-.95,0-1.47s0-1,0-1.42,0-1,0-1.49c-.17.43-.32.86-.46,1.28l-.4,1.1a8.41,8.41,0,0,1-.4,1l-2.95,6.76-3-6.76c-.12-.27-.26-.6-.39-1s-.27-.74-.4-1.09-.29-.84-.43-1.27c0,.51,0,1,0,1.53v2.82l-.17,8.8h-1.9l.58-16.83h1.54l4.14,10.22,4.16-10.22h1.51l.58,16.83Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M516.39,309.43V292.6h6.71v1.75h-4.81v5.22h4.33v1.64h-4.33v6.47h4.81v1.75Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M534,309.43l-1-4.3a7.39,7.39,0,0,0-.39-1.31,4.32,4.32,0,0,0-.45-.89,2.14,2.14,0,0,0-.45-.54,1.64,1.64,0,0,0-.41-.29l-.9.08c-.33,0-.59,0-.8,0v7.21h-1.9V292.6h3a10.3,10.3,0,0,1,2.19.21,3.94,3.94,0,0,1,1.61.75,3.31,3.31,0,0,1,1,1.42,6.48,6.48,0,0,1,.33,2.23,4.85,4.85,0,0,1-.75,2.89,4.5,4.5,0,0,1-2,1.52,2.83,2.83,0,0,1,1.12,1.26,11.33,11.33,0,0,1,.61,1.79l1.28,4.76Zm0-12.22a5.25,5.25,0,0,0-.17-1.5,1.75,1.75,0,0,0-.56-.91,2.17,2.17,0,0,0-1-.45,9.29,9.29,0,0,0-1.63-.12h-1v6.54a15.61,15.61,0,0,0,1.81-.18,3.53,3.53,0,0,0,1.38-.49,2.23,2.23,0,0,0,.88-1.05A4.64,4.64,0,0,0,534,297.21Z"
      transform="translate(-28.86 -38.81)"
    />
    <path
      fill="#ffffff"
      d="M546.7,305.12a5.58,5.58,0,0,1-.44,2.41,3.74,3.74,0,0,1-1.1,1.37,3.49,3.49,0,0,1-1.45.63,8.93,8.93,0,0,1-1.51.14,6.44,6.44,0,0,1-.89-.06,6.2,6.2,0,0,1-.77-.15c-.27-.07-.52-.14-.75-.22v-1.78a5.29,5.29,0,0,0,.8.24c.22.06.48.12.77.17a4.92,4.92,0,0,0,.84.07,4.09,4.09,0,0,0,1-.12,2.11,2.11,0,0,0,.83-.42,1.94,1.94,0,0,0,.56-.83,3.68,3.68,0,0,0,.21-1.35,4,4,0,0,0-.15-1.16,3.22,3.22,0,0,0-.39-.88,3.78,3.78,0,0,0-.55-.7l-.68-.65-1.4-1.3a5.79,5.79,0,0,1-1.17-1.54,4.75,4.75,0,0,1-.5-2.28,5.41,5.41,0,0,1,.37-2.12,3.58,3.58,0,0,1,1-1.33,3.66,3.66,0,0,1,1.32-.7,5.07,5.07,0,0,1,1.42-.2,5.19,5.19,0,0,1,.8.06c.25,0,.5.08.72.13l.72.19v1.71a6.1,6.1,0,0,0-.72-.23l-.72-.15a4,4,0,0,0-.79-.08,2.21,2.21,0,0,0-1.58.6,2.58,2.58,0,0,0-.62,1.91,3.23,3.23,0,0,0,.41,1.71,5.91,5.91,0,0,0,1,1.2l1.3,1.23c.27.26.53.53.79.82a6.12,6.12,0,0,1,.68,1,5.29,5.29,0,0,1,.48,1.19A5.84,5.84,0,0,1,546.7,305.12Z"
      transform="translate(-28.86 -38.81)"
    />
  </svg>
);
