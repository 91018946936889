export var ApiEmployerTemplateHistoryMonthStatus;
(function (ApiEmployerTemplateHistoryMonthStatus) {
    ApiEmployerTemplateHistoryMonthStatus["Todo"] = "0";
    ApiEmployerTemplateHistoryMonthStatus["Scheduled"] = "1";
    ApiEmployerTemplateHistoryMonthStatus["InProgress"] = "2";
    ApiEmployerTemplateHistoryMonthStatus["Completed"] = "3";
    ApiEmployerTemplateHistoryMonthStatus["Error"] = "4";
    ApiEmployerTemplateHistoryMonthStatus["Uploaded"] = "5";
    ApiEmployerTemplateHistoryMonthStatus["Cancelled"] = "6";
    ApiEmployerTemplateHistoryMonthStatus["FileReady"] = "7";
})(ApiEmployerTemplateHistoryMonthStatus || (ApiEmployerTemplateHistoryMonthStatus = {}));
