import styled from 'styled-components';

import type { StyleProps } from './props';
import { flexProps, marginProps, paddingProps } from './props';

export default styled.div<StyleProps>`
  display: flex;
  ${flexProps};
  ${marginProps};
  ${paddingProps};
`;
