import { useTranslation } from 'react-i18next';

import HttpCodePage from '../components/HttpCodePage';
import Illustration from './Illustration';

export default () => {
  const { t } = useTranslation();

  return (
    <HttpCodePage code="401" label={t('http.401.title')}>
      <Illustration />
    </HttpCodePage>
  );
};
