import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { useAuth } from '@pib/auth';
import { initialize as initializeI18n } from 'core/i18n';

interface Props {
  children: ReactNode;
  placeholder: ReactNode;
}

const AppInitializer = ({ children, placeholder }: Props) => {
  const [initialized, setInitialized] = useState(false);
  const { initialize: initializeAuth } = useAuth();

  useEffect(() => {
    Promise.all([initializeAuth(), initializeI18n()]).then(() => {
      setInitialized(true);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{initialized ? children : placeholder}</>;
};

export default AppInitializer;
