import styled from 'styled-components';

import type { StyleProps } from './props';
import {
  colorProps,
  flexProps,
  fontSizeProps,
  fontWeightProps,
  marginProps,
  textAlignProps,
} from './props';

export default styled.span<StyleProps>`
  margin: 0;
  ${flexProps};
  ${marginProps};
  ${fontWeightProps};
  ${fontSizeProps};
  ${colorProps('color')};
  ${textAlignProps};
`;
