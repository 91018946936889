class ConfigApi {
    constructor(fetchService) {
        this.fetchService = fetchService;
    }
    getEmployeeConfig() {
        return this.fetchService.get('/config/employee');
    }
    getEmployerConfig() {
        return this.fetchService.get('/config/employer');
    }
}
export default ConfigApi;
