import type { FC } from 'react';
import clsx from 'clsx';

import styles from './index.module.css';

interface Props {
  className?: string;
  label?: string;
}

const Spinner: FC<Props> = ({ className, label }) => (
  <div className={clsx('flex flex-col items-center my-4', className)}>
    <div
      data-testid="spinner"
      className="transform rotate-45 relative h-5 w-5 mx-auto"
    >
      <div className={clsx(styles.cube, styles.cube1)} />
      <div className={clsx(styles.cube, styles.cube2)} />
      <div className={clsx(styles.cube, styles.cube4)} />
      <div className={clsx(styles.cube, styles.cube3)} />
    </div>
    {label && <div className="mt-2">{label}</div>}
  </div>
);

export default Spinner;
